import { useState, useEffect } from "react"
import { useGetClusterEventsInfoQuery, useGetClusterEventsQuery, useAddEventsClusterMutation } from './clusterApiSlice'
import { useGetEventsFilteredQuery } from "./../oracly/oraclyApiSlice"
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// immport screen
import { LoadingScreen } from "./../../screens";

// import `ChakraProvider` component
import { css, Box, Grid, GridItem, Center, HStack, VStack, Tag, Badge, Skeleton, Stack, Spacer, Textarea, Input, Select, Switch, Icon, Button, Text, useToast, Checkbox } from '@chakra-ui/react'
import { FormControl, FormLabel } from "@chakra-ui/react"
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, } from '@chakra-ui/react'


// import `ChakraProvider` icon
import { ArrowBackIcon, CheckIcon, AddIcon, CloseIcon, RepeatIcon } from '@chakra-ui/icons'

import { FiMenu, FiChevronRight, FiCalendar, FiMapPin, FiMinusSquare, FiPlusSquare, FiEdit, FiCopy, FiSave, FiStar, FiPlus, FiMinus, FiPause, FiPlay, FiGlobe, FiAward, FiUpload } from "react-icons/fi";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faGear, faCaretLeft, faBackward, faBackwardFast, faCaretRight, faForward, faForwardFast } from "@fortawesome/free-solid-svg-icons"

const EditClusterForm = ({ cluster, events }) => {

    const { isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const navigate = useNavigate()

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    const [clusterEvents, setClusterEvents] = useState(events)


    const [dataNav, setDataNav] = useState([])
    const [Page, setPage] = useState(1)
    const [Pagination, setPagination] = useState(10)
    const [nbPaginations, setNbPaginations] = useState([10, 20, 50, 100]);

    const [nbPages, setNbPages] = useState([1])
    const [nbEvents, setNbEvents] = useState(null)

    const {
        data: infoDb,
        isSuccess: isSuccessInfoDb,
        refetch: refetchInfoDb,
    } = useGetClusterEventsInfoQuery({
        id: cluster._id,
    })
    if (isSuccessInfoDb && infoDb && nbEvents == null) {
        setNbEvents(infoDb.nbEvents)
        var tmp = Math.ceil(infoDb.nbEvents / Pagination)
        setNbPages(Array.from({ length: tmp }, (_, i) => i + 1));
    }

    const [isReloadingDb, setIsReloadingDb] = useState(false);
    const {
        data: dataDb,
        isLoading: isLoadingDb,
        isSuccess: isSuccessDb,
        isError: isErrorDb,
        error: errorDb,
        refetch: refetchDb,
    } = useGetClusterEventsQuery({
        id: cluster._id,
        Pagination: Pagination,
        Page: Page
    });
    // Fonction pour relancer l'appel
    const onDbEventsClicked = async () => {
        try {
            setIsReloadingDb(true);
            await refetchDb();
            if (isSuccessDb) {
                toastInfo({ title: 'CLUSTER : refresh successfully', status: 'success' })
                setIsReloadingDb(false);
            } else {
                toastInfo({ title: 'CLUSTER : refresh error (' + errorUp?.message + ')', status: 'error' })
                setIsReloadingDb(false);
            }

        } catch (error) {
            // Gérer les erreurs ici
            setIsReloadingDb(false);
        }
    }

    //    

    const [isReloadingImports, setIsReloadingImports] = useState(false);
    const {
        data: dataImports,
        isLoading: isLoadingImports,
        isSuccess: isSuccessImports,
        isError: isErrorImports,
        error: errorImports,
        refetch: refetchImports,
    } = useGetEventsFilteredQuery({
        marketFilter: cluster.clusterDataFilter[0].filterMarket.map(option => option.value) || [],
        countryFilter: cluster.clusterDataFilter[0].filterCountry.map(option => option.value) || [],
        cityFilter: cluster.clusterDataFilter[0].filterCity.map(option => option.value) || [],
        venueFilter: cluster.clusterDataFilter[0].filterVenue.map(option => option.value) || [],
        customerFilter: cluster.clusterDataFilter[0].filterCustomer.map(option => option.value) || [],
        categoryFilter: cluster.clusterDataFilter[0].filterCategory.map(option => option.value) || [],
        eventFilter: cluster.clusterDataFilter[0].filterEvent.map(option => option.value) || [],
        eventsInCluster: events ? events.map(item => item._id) : [],
    });
    // Fonction pour relancer l'appel
    const onExtractEventsClicked = async () => {
        try {
            setIsReloadingImports(true);
            await refetchImports();
            if (isSuccessImports) {
                toastInfo({ title: 'CLUSTER : refresh successfully', status: 'success' })
                setIsReloadingImports(false);
            } else {
                toastInfo({ title: 'CLUSTER : refresh error (' + errorUp?.message + ')', status: 'error' })
                setIsReloadingImports(false);
            }

        } catch (error) {
            // Gérer les erreurs ici
            setIsReloadingImports(false);
        }
    }

    const [addEventsInCluster, {
        data: dataUp,
        isLoading: isLoadingUp,
        isSuccess: isSuccessUp,
        isError: isErrorUp,
        error: errorUp
    }] = useAddEventsClusterMutation()




    let [canAdd, setCanSave] = useState(false)




    const [clusterName, setClusterName] = useState(cluster.clusterName)
    const [clusterType, setClusterType] = useState(cluster.clusterType)
    const [clusterUrl, setClusterUrl] = useState(cluster.clusterUrl)
    const [clusterDatabaseURI, setClusterDatabaseURI] = useState(cluster.clusterDatabaseURI)

    const [clusterStatus, setClusterStatus] = useState(cluster.clusterStatus)



    const checkCanSave = () => {
        setCanSave(true)
        if ((selectedEventIds.length <= 0)) {
            setCanSave(false)
        }
    }

    const onPageBack = () => navigate('/dash')

    const onClusterNameChanged = e => setClusterName(e.target.value)
    const onClusterTypeChanged = e => setClusterType(e.target.value)
    const onClusterUrlChanged = e => setClusterUrl(e.target.value)
    const onClusterDatabaseURIChanged = e => setClusterDatabaseURI(e.target.value)
    const onClusterStatusChanged = e => setClusterStatus(prev => !prev)



    const onAddEventsClicked = async (e) => {
        e.preventDefault()
        if (canAdd) {
            try {
                setIsReloadingImports(true);
                await addEventsInCluster({ clusterId: cluster._id, clusterAddIdsList: selectedEventIds });

                if (isSuccessImports) {
                    toastInfo({ title: 'CLUSTER : ' + dataUp?.message + ' successfully', status: 'success' })
                    setIsReloadingImports(false);
                    setSelectedIds([]);
                    await onExtractEventsClicked();
                    setIsReloadingDb(true);
                    await onDbEventsClicked();
                    await refetchInfoDb();
                    if (isSuccessUp) {
                        toastInfo({ title: 'CLUSTER : refresh', status: 'success' })
                        //        navigate(`/dash/cluster/${cluster._id}`);
                        setIsReloadingDb(false);
                    } else {
                        toastInfo({ title: 'CLUSTER : refresh error (' + errorUp?.message + ')', status: 'error' })
                        setIsReloadingDb(false);
                    }

                } else {
                    toastInfo({ title: 'CLUSTER : Update error (' + errorUp?.message + ')', status: 'error' })
                    setIsReloadingImports(false);
                }
                setSelectedIds([]);

            } catch (error) {
                console.error("Une erreur s'est produite :", error);
            }
        }
    }


    const created = new Date(cluster.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    const updated = new Date(cluster.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    const clusterTypes = [{ name: 'Tool' }, { name: 'Website' }, { name: 'Linker' }, { name: 'Shop' }, { name: 'Marketplace' }]
    const options_clusterType = clusterTypes.map(type => {
        return (
            <option
                key={type.name}
                value={type.name}
            > {type.name}</option >
        )
    })




    const [selectedEventIds, setSelectedIds] = useState([]);

    //const [test, setSelectedIds] = useState([]);

    const handleCheckboxChange = (eventId) => {
        const dataImportToDisplay = dataImports.find(dataImport => dataImport._id === eventId);

        if (dataImportToDisplay) {
            setSelectedIds(prevSelectedEventIds => {
                if (prevSelectedEventIds.some(item => item._id === eventId)) {
                    // Si l'ID est déjà présent, le retirer
                    return prevSelectedEventIds.filter(item => item._id !== eventId);
                } else {
                    // Sinon, l'ajouter
                    return [...prevSelectedEventIds, dataImportToDisplay];
                }
            });
        } else {
            // Aucun objet trouvé avec l'ID spécifié
            console.log("Aucun objet trouvé avec l'ID spécifié");
        }
    };



    const setLocalNav = (dataNav) => {
        let { Page, Pagination } = dataNav;
        localStorage.setItem('Page', Page);
        localStorage.setItem('Pagination', Pagination);
    }

    const onPageChanged = (e) => {
        setPage(eval(e.target.value))

        dataNav.Page = eval(e.target.value)
        dataNav.Pagination = Pagination
        setLocalNav(dataNav)
    }

    const onPaginationChanged = (e) => {
        setPagination(e.target.value)
        dataNav.Pagination = e.target.value
        var tmp = Math.ceil(nbEvents / e.target.value)
        setNbPages(Array.from({ length: tmp }, (_, i) => i + 1));
        if (Page > tmp) {
            setPage(tmp)
            dataNav.Page = tmp
        }

        dataNav.Page = Page
        dataNav.Pagination = eval(e.target.value)
        setLocalNav(dataNav)
    }

    const onPageNav = (e) => {
        switch (true) {
            case Page + e > nbPages.length:
                setPage(nbPages.length)
                dataNav.Page = nbPages.length
                break;
            case Page + e <= 1:
                setPage(1)
                dataNav.Page = 1
                break;
            default:
                setPage(eval(Page + e))
                dataNav.Page = Page + e
        }

        dataNav.Pagination = Pagination
        setLocalNav(dataNav)
    }


    const options_nbPage = nbPages.map((nbPage, i) => {
        return (
            <option
                key={i}
                value={nbPage}
            > {nbPage}</option >
        )
    })

    let paginationContent
    paginationContent = (
        <>

            {(function () {
                if (Page > 1) {
                    return <Button m='1'
                        title="first"
                        onClick={() => onPageNav(-99999)}
                    >
                        <FontAwesomeIcon icon={faBackwardFast} />
                    </Button>;
                }
            })()}

            {(function () {
                if (Page > 10) {
                    return <Button m='1'
                        title="-10"
                        onClick={() => onPageNav(-10)}
                    >
                        <FontAwesomeIcon icon={faBackward} />
                    </Button>;
                }
            })()}

            {(function () {
                if (Page > 1) {
                    return <Button m='1'
                        title="-1"
                        onClick={() => onPageNav(-1)}
                    >
                        <FontAwesomeIcon icon={faCaretLeft} />
                    </Button>;
                }
            })()}

            <Select
                variant="brandPrimary"
                id="Page"
                name="Page"
                value={Page}
                onChange={onPageChanged}
            >
                {options_nbPage}
            </Select>

            {(function () {
                if (Page + 1 <= nbPages.length) {
                    return <Button
                        title="+1"
                        onClick={() => onPageNav(1)}
                    >
                        <FontAwesomeIcon icon={faCaretRight} />
                    </Button>;
                }
            })()}

            {(function () {
                if (Page + 10 <= nbPages.length) {
                    return <Button m='1'
                        title="+10"
                        onClick={() => onPageNav(10)}
                    >
                        <FontAwesomeIcon icon={faForward} />
                    </Button>;
                }
            })()}

            {(function () {
                if (Page + 1 <= nbPages.length) {
                    return <Button m='1'
                        title="last"
                        onClick={() => onPageNav(99999)}
                    >
                        <FontAwesomeIcon icon={faForwardFast} />
                    </Button>;
                }
            })()}

        </>
    )

    const [isOpenEventDB, setisOpenEventDB] = useState({});
    const drawerEventDBIsOpen = (index, isOpen) => {
        if (isOpen === false) {
            //alert(index + '=' + isOpen)
            setTimeout(() => {
                //                setCell({ ...cell, focusRow: 'tt' });
                //setCell(index)

                setTimeout(() => {
                    //setCell('')
                    //                    setCell({ ...cell, focusRow: '' });
                }, 5000);

            }, 1000);

        }
        setisOpenEventDB((prevState) => {
            return {
                ...prevState,
                [index]: isOpen,
            };

        });
    };



    useEffect(() => {
        //refetchCluster();

        if (isSuccessInfoDb) {
            setNbEvents(infoDb.nbEvents)
            var tmp = Math.ceil(infoDb.nbEvents / Pagination)
            setNbPages(Array.from({ length: tmp }, (_, i) => i + 1));
        }


        checkCanSave()
        setClusterEvents(events)
    }, [isSuccessInfoDb, checkCanSave, toastInfo, events, dataUp, isSuccessUp, isErrorUp, errorUp, navigate])


    let content = (
        <>
            {cluster ? (
                <>
                    <Center bg='#45413C' p='0' color='white' axis='both'>
                        <Grid w='95vw' minH='100vh' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                            <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                                <HStack color='white'>
                                    <Box textAlign={['left']} >
                                        <HStack>
                                            <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faUsers} /> {T.clusters.OverviewCluster}</Text>
                                            <Tag m='5' p='3'>
                                                <VStack>
                                                    <Text fontSize={22} fontWeight={600}>
                                                        {clusterName}
                                                    </Text>
                                                    <Text fontSize={15} fontWeight={400}>
                                                        {clusterType}
                                                    </Text>
                                                </VStack>
                                            </Tag>
                                        </HStack>
                                    </Box>
                                    <Spacer />
                                    <Box p='2' textAlign={['right']}>
                                        <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                                            <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                                        </Button>
                                    </Box>
                                </HStack>
                                <HStack pb='2'>
                                    <Text mr='1' color='#fff' fontSize={14} fontWeight={700}> DATAFILTER
                                    </Text>
                                    <Tag mr='1' p='0.5' fontSize={13} fontWeight={500}> EVENT
                                        {cluster.clusterDataFilter[0].filterEvent && cluster.clusterDataFilter[0].filterEvent.length > 0 ? (
                                            cluster.clusterDataFilter[0].filterEvent.map(item => (
                                                <Tag ml='0.5' color='#fff' bg='#2B6CB0' key={item.value}>{item.label}</Tag>
                                            ))
                                        ) : (
                                            <Tag ml='0.5' color='#fff' bg='#999'>All</Tag>
                                        )}
                                    </Tag>
                                    <Tag mr='1' p='0.5' fontSize={13} fontWeight={500}> CATEGORY
                                        {cluster.clusterDataFilter[0].filterCategory && cluster.clusterDataFilter[0].filterCategory.length > 0 ? (
                                            cluster.clusterDataFilter[0].filterCategory.map(item => (
                                                <Tag ml='0.5' color='#fff' bg='#2B6CB0' key={item.value}>{item.label}</Tag>
                                            ))
                                        ) : (
                                            <Tag ml='0.5' color='#fff' bg='#999'>All</Tag>
                                        )}
                                    </Tag>
                                    <Tag mr='1' p='0.5' fontSize={13} fontWeight={500}> CUSTOMER
                                        {cluster.clusterDataFilter[0].filterCustomer && cluster.clusterDataFilter[0].filterCustomer.length > 0 ? (
                                            cluster.clusterDataFilter[0].filterCustomer.map(item => (
                                                <Tag ml='0.5' color='#fff' bg='#2B6CB0' key={item.value}>{item.label}</Tag>
                                            ))
                                        ) : (
                                            <Tag ml='0.5' color='#fff' bg='#999'>All</Tag>
                                        )}
                                    </Tag>
                                    <Tag mr='1' p='0.5' fontSize={13} fontWeight={500}> VENUES
                                        {cluster.clusterDataFilter[0].filterVenue && cluster.clusterDataFilter[0].filterVenue.length > 0 ? (
                                            cluster.clusterDataFilter[0].filterVenue.map(item => (
                                                <Tag ml='0.5' color='#fff' bg='#2B6CB0' key={item.value}>{item.label}</Tag>
                                            ))
                                        ) : (
                                            <Tag ml='0.5' color='#fff' bg='#999'>All</Tag>
                                        )}
                                    </Tag>
                                    <Tag mr='1' p='0.5' fontSize={13} fontWeight={500}> CITIES
                                        {cluster.clusterDataFilter[0].filterCity && cluster.clusterDataFilter[0].filterCity.length > 0 ? (
                                            cluster.clusterDataFilter[0].filterCity.map(item => (
                                                <Tag ml='0.5' color='#fff' bg='#2B6CB0' key={item.value}>{item.label}</Tag>
                                            ))
                                        ) : (
                                            <Tag ml='0.5' color='#fff' bg='#999'>All</Tag>
                                        )}
                                    </Tag>
                                    <Tag mr='1' p='0.5' fontSize={13} fontWeight={500}> COUNTRIES
                                        {cluster.clusterDataFilter[0].filterCountry && cluster.clusterDataFilter[0].filterCountry.length > 0 ? (
                                            cluster.clusterDataFilter[0].filterCountry.map(item => (
                                                <Tag ml='0.5' color='#fff' bg='#2B6CB0' key={item.value}>{item.label}</Tag>
                                            ))
                                        ) : (
                                            <Tag ml='0.5' color='#fff' bg='#999'>All</Tag>
                                        )}
                                    </Tag>
                                    <Tag mr='1' p='0.5' fontSize={13} fontWeight={500}> MARKETS<br />
                                        {cluster.clusterDataFilter[0].filterMarket && cluster.clusterDataFilter[0].filterMarket.length > 0 ? (
                                            cluster.clusterDataFilter[0].filterMarket.map(item => (
                                                <Tag ml='0.5' color='#fff' bg='#2B6CB0' key={item.value}>{item.label}</Tag>
                                            ))
                                        ) : (
                                            <Tag ml='0.5' color='#fff' bg='#999'>All</Tag>
                                        )}
                                    </Tag>

                                </HStack>
                                <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                                    <form onSubmit={e => e.preventDefault()}>

                                        <Tabs variant='soft-rounded' colorScheme='blue' isFitted>
                                            <TabList
                                                overflowX="auto"
                                                css={css({
                                                    scrollbarWidth: 'none',
                                                    '::-webkit-scrollbar': { display: 'none' },
                                                    '-webkit-overflow-scrolling': 'touch',
                                                    boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                                    border: '0 none',
                                                })}
                                            >
                                                <Tab bg='gray.200' _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Event<Spacer /><Badge fontSize={16} fontWeight={600} w='50px' color='black' bg='#fff' p='1' borderRadius='20' borderWidth='1px' borderColor='gray.300'>{nbEvents && nbEvents}</Badge></Tab>
                                                <Tab bg='gray.200' _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Category<Spacer /><Badge fontSize={16} fontWeight={600} w='50px' color='black' bg='#fff' p='1' borderRadius='20' borderWidth='1px' borderColor='gray.300'>0</Badge></Tab>
                                                <Tab bg='gray.200' _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Customer<Spacer /><Badge fontSize={16} fontWeight={600} w='50px' color='black' bg='#fff' p='1' borderRadius='20' borderWidth='1px' borderColor='gray.300'>0</Badge></Tab>
                                                <Tab bg='gray.200' _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Venue<Spacer /><Badge fontSize={16} fontWeight={600} w='50px' color='black' bg='#fff' p='1' borderRadius='20' borderWidth='1px' borderColor='gray.300'>0</Badge></Tab>
                                                <Tab bg='gray.200' _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>City<Spacer /><Badge fontSize={16} fontWeight={600} w='50px' color='black' bg='#fff' p='1' borderRadius='20' borderWidth='1px' borderColor='gray.300'>0</Badge></Tab>
                                                <Tab bg='gray.200' _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Country<Spacer /><Badge fontSize={16} fontWeight={600} w='50px' color='black' bg='#fff' p='1' borderRadius='20' borderWidth='1px' borderColor='gray.300'>0</Badge></Tab>
                                                <Tab bg='gray.200' _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Market<Spacer /><Badge fontSize={16} fontWeight={600} w='50px' color='black' bg='#fff' p='1' borderRadius='20' borderWidth='1px' borderColor='gray.300'>0</Badge></Tab>
                                            </TabList>

                                            <TabPanels>

                                                {/* Tab EVENT */}
                                                <TabPanel minH={600}>

                                                    <HStack mt='5'>
                                                        <Text fontSize={20} fontWeight={700}>NEWS EVENTS :</Text>
                                                        {isLoadingImports || isReloadingImports ? (
                                                            <>
                                                                <Text fontSize={20} fontWeight={500}>Searching...</Text>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Text fontSize={20} fontWeight={500}>{dataImports && dataImports.length}</Text>
                                                                <Spacer />
                                                                <Button
                                                                    size='sm'
                                                                    onClick={onExtractEventsClicked}
                                                                    leftIcon={<RepeatIcon />}
                                                                    colorScheme='gray'
                                                                    variant='solid'
                                                                    mr='1'
                                                                >
                                                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={16} fontWeight={600}>{T.common.Refresh}</Text>
                                                                </Button>
                                                                <Button
                                                                    size='sm'
                                                                    onClick={onAddEventsClicked}
                                                                    leftIcon={<AddIcon />}
                                                                    disabled={!canAdd}
                                                                    colorScheme={canAdd ? "blue" : "gray"}
                                                                >
                                                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={16} fontWeight={600}>{T.common.Add}</Text>
                                                                </Button>
                                                            </>
                                                        )}
                                                    </HStack>
                                                    <Box mt='1' p='2' bg='gray.50' borderRadius='10' borderWidth='1px' borderColor='gray.300'>
                                                        <TableContainer>
                                                            <Table
                                                                variant='striped'
                                                                size='sm'
                                                            >
                                                                <Thead>
                                                                    <Tr>
                                                                        <Th>
                                                                            <Checkbox colorScheme='blue' mr='1' />
                                                                            EVENT
                                                                        </Th>
                                                                        <Th>DATE</Th>
                                                                        <Th>VENUE</Th>
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {isLoadingImports || isReloadingImports ? (
                                                                        // Afficher des skeletons pendant le chargement
                                                                        <>
                                                                            {Array.from({ length: 20 }).map((_, index) => (
                                                                                <Tr key={index}>
                                                                                    <Td>
                                                                                        <Skeleton height='14px' />
                                                                                    </Td>
                                                                                    <Td>
                                                                                        <Skeleton height='14px' />
                                                                                    </Td>
                                                                                    <Td>
                                                                                        <Skeleton height='14px' />
                                                                                    </Td>
                                                                                </Tr>
                                                                            ))}
                                                                        </>
                                                                    ) : isSuccessImports ? (
                                                                        // Afficher les données une fois qu'elles sont disponibles
                                                                        dataImports && dataImports.length > 0 ? (
                                                                            // Afficher les données réelles
                                                                            dataImports.map(dataImport => (
                                                                                <Tr key={dataImport.event.id} _hover={{ color: '#333333', border: '2px', borderColor: 'blue.600' }}>
                                                                                    <Td>
                                                                                        <Checkbox
                                                                                            colorScheme='blue'
                                                                                            mr='1'
                                                                                            onChange={() => handleCheckboxChange(dataImport._id)}
                                                                                            //isChecked={selectedEventIds.includes(dataImport._id)}
                                                                                            isChecked={selectedEventIds.some(item => item._id === dataImport._id)}
                                                                                        />
                                                                                        <strong>{dataImport.event.eventName}</strong>
                                                                                    </Td>
                                                                                    <Td>{dataImport.event.eventDateStart}, {dataImport.event.eventHourStart}</Td>
                                                                                    <Td>{dataImport.venue.venueName[lang]} ({dataImport.venue.countryName[lang]})</Td>
                                                                                </Tr>
                                                                            ))
                                                                        ) : (
                                                                            // Afficher un message si aucune donnée n'est disponible
                                                                            <Tr>
                                                                                <Td colSpan={4} height='50px' textAlign='center'>
                                                                                    <Text color="#777" fontSize={17} fontWeight={400}>- no new event found -</Text>
                                                                                </Td>
                                                                            </Tr>
                                                                        )
                                                                    ) : isErrorImports ? (
                                                                        // Afficher un message d'erreur en cas d'échec de la requête
                                                                        {/* <ErrorComponent error={errorImports} /> */ }
                                                                    ) : null}

                                                                    {/*
                                                                    {isLoadingImports || isReloadingImports ? (
                                                                        // Afficher des skeletons pendant le chargement
                                                                        <Tr>
                                                                            {Array.from({ length: 4 }).map((_, index) => (
                                                                                <Td key={index}>
                                                                                    <Stack>
                                                                                        <Skeleton height='15px' />
                                                                                        <Skeleton height='15px' />
                                                                                        <Skeleton height='15px' />
                                                                                    </Stack>
                                                                                </Td>
                                                                            ))}
                                                                        </Tr>
                                                                    ) : (
                                                                        dataImports && dataImports.length > 0 ? (
                                                                            // Afficher les données réelles
                                                                            dataImports.map(dataImport => (
                                                                                <Tr key={dataImport.event._id} _hover={{ color: '#333333', border: '2px', borderColor: 'blue.600' }}>
                                                                                    <Td><strong>{dataImport.event.eventName}</strong></Td>
                                                                                    <Td>{dataImport.event.eventDateStart}, {dataImport.event.eventHourStart}</Td>
                                                                                    <Td>{dataImport.venue.venueName[lang]} ({dataImport.venue.countryName[lang]})</Td>
                                                                                    <Td isNumeric><i>ready to add</i></Td>
                                                                                </Tr>
                                                                            ))
                                                                        ) : (
                                                                            // Afficher un message si aucune donnée n'est disponible
                                                                            <Tr>
                                                                                <Td colSpan={4} height='50px' textAlign='center'>
                                                                                    <Text color="#777" fontSize={17} fontWeight={400}>- no new event found -</Text>
                                                                                </Td>
                                                                            </Tr>
                                                                        )
                                                                    )}
*/}
                                                                </Tbody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Box>

                                                    <HStack mt='5'>
                                                        <Text fontSize={20} fontWeight={700}>DB EVENTS :</Text>
                                                        {isLoadingDb || isReloadingDb ? (
                                                            <>
                                                                <Text fontSize={20} fontWeight={500}>Searching...</Text>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Text fontSize={20} fontWeight={500}>{nbEvents && nbEvents}</Text>
                                                                <Spacer />
                                                                <Button
                                                                    size='sm'
                                                                    onClick={onDbEventsClicked}
                                                                    leftIcon={<RepeatIcon />}
                                                                    colorScheme='gray'
                                                                    variant='solid'
                                                                    mr='1'
                                                                >
                                                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={16} fontWeight={600}>{T.common.Refresh}</Text>
                                                                </Button>
                                                            </>
                                                        )}
                                                    </HStack>
                                                    <Box mt='1' p='2' bg='gray.50' borderRadius='10' borderWidth='1px' borderColor='gray.300'>
                                                        <TableContainer>
                                                            <Table
                                                                variant='striped'
                                                                size='sm'
                                                            >
                                                                <Thead>
                                                                    <Tr>
                                                                        <Th>EVENT</Th>
                                                                        <Th>DATE</Th>
                                                                        <Th>VENUE</Th>
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {isLoadingDb || isReloadingDb ? (
                                                                        // Afficher le Skeleton pendant le chargement ou si les données ne sont pas disponibles
                                                                        <>
                                                                            {Array.from({ length: Pagination }).map((_, index) => (
                                                                                <Tr key={index}>
                                                                                    <Td>
                                                                                        <Skeleton height='14px' />
                                                                                    </Td>
                                                                                    <Td>
                                                                                        <Skeleton height='14px' />
                                                                                    </Td>
                                                                                    <Td>
                                                                                        <Skeleton height='14px' />
                                                                                    </Td>
                                                                                </Tr>
                                                                            ))}
                                                                        </>
                                                                    ) : (
                                                                        // Afficher les événements si des données sont disponibles
                                                                        dataDb.map((event, index) => (
                                                                            <>
                                                                                <Tr
                                                                                    key={index}
                                                                                    _hover={{ color: '#333333', border: '2px', borderColor: 'blue.600' }}
                                                                                    cursor={'pointer'}
                                                                                    onClick={() => drawerEventDBIsOpen(index, !isOpenEventDB[index])}
                                                                                >
                                                                                    <Td><strong>{event.event.eventName}</strong></Td>
                                                                                    <Td>{event.event.eventDateStart}, {event.event.eventHourStart}</Td>
                                                                                    <Td>{event.venue.venueName[lang]} ({event.venue.countryName[lang]})</Td>
                                                                                </Tr>

                                                                                {/* EventDB drawer */}
                                                                                <Drawer
                                                                                    key={index}
                                                                                    id={index}
                                                                                    isOpen={isOpenEventDB[index] ?? false}
                                                                                    onClose={() => drawerEventDBIsOpen(index, false)}
                                                                                    size='xl'
                                                                                    placement='bottom'
                                                                                >
                                                                                    <DrawerOverlay />
                                                                                    <DrawerContent bg='#CCCCCC'>
                                                                                        <DrawerCloseButton />
                                                                                        <DrawerHeader>EVENT DATA</DrawerHeader>
                                                                                        <DrawerBody>
                                                                                            <Box
                                                                                                bg='#efefef'
                                                                                                borderRadius='xl'
                                                                                                borderWidth="1px"
                                                                                                borderColor='#dfdfdf'
                                                                                                shadow="lg"
                                                                                                position="relative"
                                                                                                textAlign={['center']}
                                                                                                mb='5'
                                                                                            >
                                                                                                <Grid w='100%' gap={2} p='2' templateColumns='repeat(12, 1fr)'>

                                                                                                    {/* Event title */}
                                                                                                    <GridItem color='black' colSpan={{ base: "6", sm: "6", md: "6", lg: "6", xl: "6", "2xl": "6" }}>
                                                                                                        <Box p={2} textAlign={['left']}>
                                                                                                            <HStack>
                                                                                                                <Box w='100px' h='100px' mr='3' borderRadius='xl' border='1px' borderColor='#2B6CB0'>
                                                                                                                    <Box p={1} borderRadius='xl' border='2px' bg='#2B6CB0' borderColor='#ffffff' textAlign={['center']}>
                                                                                                                        <Text color='white' fontSize={15} fontWeight={600}>
                                                                                                                            Sept. 2023
                                                                                                                        </Text>
                                                                                                                    </Box>
                                                                                                                    <Box borderRadius='xl' m={0} p={0} color='white' bg='#ffffff' textAlign={['center']}>
                                                                                                                        <Text color='black' fontSize={27} fontWeight={700}>
                                                                                                                            22
                                                                                                                        </Text>
                                                                                                                        <Text color='black' fontSize={15} fontWeight={500}>
                                                                                                                            eventHour
                                                                                                                        </Text>
                                                                                                                    </Box>
                                                                                                                </Box>
                                                                                                                <Box>
                                                                                                                    <Text fontSize={25} fontWeight={700}>
                                                                                                                        {event.event.eventName}
                                                                                                                    </Text>
                                                                                                                    <Text fontSize={20} fontWeight={400} color='#1B7392'><Icon as={FiMapPin} h={4} w={4} mr={1} alignSelf={'center'} />{event.venue.venueName[lang]} ({event.venue.countryName[lang]})</Text>
                                                                                                                </Box>
                                                                                                            </HStack>
                                                                                                        </Box>
                                                                                                    </GridItem>

                                                                                                    <GridItem color='white' textAlign={'center'} colSpan={{ base: "2", sm: "2", md: "2", lg: "2", xl: "2", "2xl": "2" }}>
                                                                                                        <Box
                                                                                                            h='100px'
                                                                                                            bg='#2B6CB0'
                                                                                                            _hover={{ bg: '#36C9FF' }}
                                                                                                            borderRadius='xl'
                                                                                                        >
                                                                                                            - Stat 1 -
                                                                                                        </Box>
                                                                                                    </GridItem>

                                                                                                    <GridItem color='white' textAlign={'center'} colSpan={{ base: "2", sm: "2", md: "2", lg: "2", xl: "2", "2xl": "2" }}>
                                                                                                        <Box
                                                                                                            h='100px'
                                                                                                            bg='#2B6CB0'
                                                                                                            _hover={{ bg: '#36C9FF' }}
                                                                                                            borderRadius='xl'
                                                                                                        >
                                                                                                            - Stat 2 -
                                                                                                        </Box>
                                                                                                    </GridItem>

                                                                                                    <GridItem color='white' textAlign={'center'} colSpan={{ base: "2", sm: "2", md: "2", lg: "2", xl: "2", "2xl": "2" }}>
                                                                                                        <Box
                                                                                                            h='100px'
                                                                                                            bg='#2B6CB0'
                                                                                                            _hover={{ bg: '#36C9FF' }}
                                                                                                            borderRadius='xl'
                                                                                                        >
                                                                                                            - Stat 3 -
                                                                                                        </Box>
                                                                                                    </GridItem>

                                                                                                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                                                                                                        <Box
                                                                                                            bg="white"
                                                                                                            borderRadius='xl'
                                                                                                            borderWidth="1px"
                                                                                                            borderColor='#dfdfdf'
                                                                                                        >

                                                                                                            <Tabs variant='soft-rounded' colorScheme='blue' isFitted>
                                                                                                                <TabList
                                                                                                                    overflowX="auto"
                                                                                                                    css={css({
                                                                                                                        scrollbarWidth: 'none',
                                                                                                                        '::-webkit-scrollbar': { display: 'none' },
                                                                                                                        '-webkit-overflow-scrolling': 'touch',
                                                                                                                        boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                                                                                                        border: '0 none',
                                                                                                                    })}
                                                                                                                >
                                                                                                                    <Tab bg='gray.200' _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Source LINK</Tab>
                                                                                                                    <Tab bg='gray.200' _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Content PAGE</Tab>
                                                                                                                    <Tab bg='gray.200' _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Metadata SEO</Tab>
                                                                                                                    <Tab bg='gray.200' _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Database CLUSTER</Tab>
                                                                                                                    <Tab bg='gray.200' _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Statistic VISIT</Tab>
                                                                                                                </TabList>

                                                                                                                <TabPanels>

                                                                                                                    {/* Tab SOURCE */}
                                                                                                                    <TabPanel minH={600}>

                                                                                                                        <Tabs variant='soft-rounded' colorScheme='teal' isFitted>
                                                                                                                            <TabList
                                                                                                                                overflowX="auto"
                                                                                                                                css={css({
                                                                                                                                    scrollbarWidth: 'none',
                                                                                                                                    '::-webkit-scrollbar': { display: 'none' },
                                                                                                                                    '-webkit-overflow-scrolling': 'touch',
                                                                                                                                    boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                                                                                                                    border: '0 none',
                                                                                                                                })}
                                                                                                                            >
                                                                                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>T1</Tab>
                                                                                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>T2</Tab>
                                                                                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>T3</Tab>
                                                                                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>T4</Tab>
                                                                                                                            </TabList>

                                                                                                                            <TabPanels>

                                                                                                                                {/* Tab T1 */}
                                                                                                                                <TabPanel minH={400}>
                                                                                                                                    <Grid
                                                                                                                                        templateRows='repeat(1, 1fr)'
                                                                                                                                        templateColumns='repeat(5, 1fr)'
                                                                                                                                        gap={5}
                                                                                                                                    >
                                                                                                                                        <GridItem colSpan={5}>
                                                                                                                                            <Box>
                                                                                                                                                <HStack>
                                                                                                                                                    <FormControl>
                                                                                                                                                        <FormLabel htmlFor='EventName' mt='5' mb='0'>
                                                                                                                                                            {T.events.EventName}
                                                                                                                                                        </FormLabel>
                                                                                                                                                        <Input
                                                                                                                                                            name='eventName'
                                                                                                                                                            value={event.event.eventName}
                                                                                                                                                            size='lg'
                                                                                                                                                            fontWeight={700}
                                                                                                                                                            fontSize='20'
                                                                                                                                                            bg='gray.100'
                                                                                                                                                        />
                                                                                                                                                    </FormControl>
                                                                                                                                                </HStack>

                                                                                                                                            </Box>
                                                                                                                                        </GridItem>
                                                                                                                                    </Grid>
                                                                                                                                </TabPanel>
                                                                                                                            </TabPanels>
                                                                                                                        </Tabs>

                                                                                                                    </TabPanel>



                                                                                                                    {/* Tab CONTENT */}
                                                                                                                    <TabPanel minH={600}>

                                                                                                                        <FormControl>
                                                                                                                            <FormLabel htmlFor='EventName' mt='5' mb='0'>
                                                                                                                                Content INTRO
                                                                                                                            </FormLabel>
                                                                                                                            <Textarea
                                                                                                                                name='eventName'
                                                                                                                                value=''
                                                                                                                                size='lg'
                                                                                                                                rows='10'
                                                                                                                                resize='vertical'
                                                                                                                                fontWeight={700}
                                                                                                                                fontSize='20'
                                                                                                                                bg='gray.100'
                                                                                                                            />
                                                                                                                        </FormControl>

                                                                                                                    </TabPanel>



                                                                                                                    {/* Tab METADATA */}
                                                                                                                    <TabPanel minH={600}>

                                                                                                                        <Tabs variant='soft-rounded' colorScheme='blue'>
                                                                                                                            <TabList
                                                                                                                                overflowX="auto"
                                                                                                                                css={css({
                                                                                                                                    scrollbarWidth: 'none',
                                                                                                                                    '::-webkit-scrollbar': { display: 'none' },
                                                                                                                                    '-webkit-overflow-scrolling': 'touch',
                                                                                                                                    boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                                                                                                                    border: '0 none',
                                                                                                                                })}
                                                                                                                            >
                                                                                                                                <Tab _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>EN</Tab>
                                                                                                                                <Tab _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>FR</Tab>
                                                                                                                                <Tab _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>ES</Tab>
                                                                                                                            </TabList>

                                                                                                                            <TabPanels>

                                                                                                                                {/* Tab EN */}
                                                                                                                                <TabPanel minH={400}>
                                                                                                                                    <FormControl>
                                                                                                                                        <FormLabel htmlFor='EventName'>
                                                                                                                                            Meta TITLE
                                                                                                                                        </FormLabel>
                                                                                                                                        <Input
                                                                                                                                            name='eventName'
                                                                                                                                            value=''
                                                                                                                                            size='lg'
                                                                                                                                            fontWeight={700}
                                                                                                                                            fontSize='20'
                                                                                                                                            bg='gray.100'
                                                                                                                                        />
                                                                                                                                    </FormControl>
                                                                                                                                    <FormControl>
                                                                                                                                        <FormLabel htmlFor='EventName' mt='5' mb='0'>
                                                                                                                                            Meta DESCRIPTION
                                                                                                                                        </FormLabel>
                                                                                                                                        <Textarea
                                                                                                                                            name='eventName'
                                                                                                                                            value=''
                                                                                                                                            size='md'
                                                                                                                                            rows='5'
                                                                                                                                            resize='vertical'
                                                                                                                                            fontWeight={700}
                                                                                                                                            fontSize='20'
                                                                                                                                            bg='gray.100'
                                                                                                                                        />
                                                                                                                                    </FormControl>
                                                                                                                                    <FormControl>
                                                                                                                                        <FormLabel htmlFor='EventName' mt='5' mb='0'>
                                                                                                                                            Meta KEYWORD
                                                                                                                                        </FormLabel>
                                                                                                                                        <Input
                                                                                                                                            name='eventName'
                                                                                                                                            value={event.event.eventName}
                                                                                                                                            size='md'
                                                                                                                                            fontWeight={700}
                                                                                                                                            fontSize='20'
                                                                                                                                            bg='gray.100'
                                                                                                                                        />
                                                                                                                                    </FormControl>
                                                                                                                                </TabPanel>

                                                                                                                                {/* Tab FR */}
                                                                                                                                <TabPanel minH={400}>
                                                                                                                                </TabPanel>

                                                                                                                                {/* Tab ES */}
                                                                                                                                <TabPanel minH={400}>
                                                                                                                                </TabPanel>

                                                                                                                            </TabPanels>

                                                                                                                        </Tabs>

                                                                                                                    </TabPanel>



                                                                                                                    {/* Tab DATABASE */}
                                                                                                                    <TabPanel minH={600}>

                                                                                                                        <Tabs variant='soft-rounded' colorScheme='teal' isFitted>
                                                                                                                            <TabList
                                                                                                                                overflowX="auto"
                                                                                                                                css={css({
                                                                                                                                    scrollbarWidth: 'none',
                                                                                                                                    '::-webkit-scrollbar': { display: 'none' },
                                                                                                                                    '-webkit-overflow-scrolling': 'touch',
                                                                                                                                    boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                                                                                                                    border: '0 none',
                                                                                                                                })}
                                                                                                                            >
                                                                                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Event</Tab>
                                                                                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Picture</Tab>
                                                                                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Venue</Tab>
                                                                                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Customer</Tab>
                                                                                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Ticket</Tab>
                                                                                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Map</Tab>
                                                                                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Source</Tab>
                                                                                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Cal</Tab>
                                                                                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}><FontAwesomeIcon icon={faGear} /></Tab>
                                                                                                                            </TabList>

                                                                                                                            <TabPanels>

                                                                                                                                {/* Tab EVENT */}
                                                                                                                                <TabPanel minH={400}>
                                                                                                                                    <Grid
                                                                                                                                        templateRows='repeat(1, 1fr)'
                                                                                                                                        templateColumns='repeat(5, 1fr)'
                                                                                                                                        gap={5}
                                                                                                                                    >
                                                                                                                                        <GridItem colSpan={5}>
                                                                                                                                            <Box>
                                                                                                                                                <HStack>
                                                                                                                                                    <FormControl>
                                                                                                                                                        <FormLabel htmlFor='EventName' mt='5' mb='0'>
                                                                                                                                                            {T.events.EventName}
                                                                                                                                                        </FormLabel>
                                                                                                                                                        <Input
                                                                                                                                                            name='eventName'
                                                                                                                                                            value={event.event.eventName}
                                                                                                                                                            size='lg'
                                                                                                                                                            fontWeight={700}
                                                                                                                                                            fontSize='20'
                                                                                                                                                            bg='gray.100'
                                                                                                                                                        />
                                                                                                                                                    </FormControl>
                                                                                                                                                </HStack>

                                                                                                                                                <HStack>
                                                                                                                                                    <FormControl>
                                                                                                                                                        <FormLabel htmlFor='DateStart' mt='5' mb='0'>
                                                                                                                                                            {T.events.DateStart}
                                                                                                                                                        </FormLabel>
                                                                                                                                                        <Input
                                                                                                                                                            name='eventDateStart'
                                                                                                                                                            value={event.event.eventDateStart}
                                                                                                                                                            type="date"
                                                                                                                                                            min="1997-01-01"
                                                                                                                                                            max="2030-12-31"
                                                                                                                                                            size='md'
                                                                                                                                                            bg='gray.100'
                                                                                                                                                        />
                                                                                                                                                    </FormControl>
                                                                                                                                                    <FormControl>
                                                                                                                                                        <FormLabel htmlFor='HourOpen' mt='5' mb='0'>
                                                                                                                                                            {T.events.HourOpen}
                                                                                                                                                        </FormLabel>
                                                                                                                                                        <Input
                                                                                                                                                            name='eventHourOpen'
                                                                                                                                                            value={event.event.eventHourOpen}
                                                                                                                                                            type="time"
                                                                                                                                                            size='md'
                                                                                                                                                            bg='gray.100'
                                                                                                                                                        />
                                                                                                                                                    </FormControl>
                                                                                                                                                    <FormControl>
                                                                                                                                                        <FormLabel htmlFor='HourStart' mt='5' mb='0'>
                                                                                                                                                            {T.events.HourStart}
                                                                                                                                                        </FormLabel>
                                                                                                                                                        <Input
                                                                                                                                                            name='eventHourStart'
                                                                                                                                                            value={event.event.eventHourStart}
                                                                                                                                                            type="time"
                                                                                                                                                            size='md'
                                                                                                                                                            bg='gray.100'
                                                                                                                                                        />
                                                                                                                                                    </FormControl>
                                                                                                                                                </HStack>

                                                                                                                                                <HStack>
                                                                                                                                                    <FormControl>
                                                                                                                                                        <FormLabel htmlFor='CategoryId' mt='5' mb='0'>
                                                                                                                                                            {T.events.Category}
                                                                                                                                                        </FormLabel>
                                                                                                                                                        <Select
                                                                                                                                                            name='eventCategoryId'
                                                                                                                                                            placeholder=' '
                                                                                                                                                            value={event.event.eventCategoryId}
                                                                                                                                                            size='md'
                                                                                                                                                            bg='gray.100'
                                                                                                                                                        >
                                                                                                                                                            options_categories
                                                                                                                                                        </Select>
                                                                                                                                                    </FormControl>
                                                                                                                                                    <FormControl>
                                                                                                                                                        <FormLabel htmlFor='TourName' mt='5' mb='0'>
                                                                                                                                                            {T.events.TourName}
                                                                                                                                                        </FormLabel>
                                                                                                                                                        <Input
                                                                                                                                                            name='eventTourName'
                                                                                                                                                            value={event.event.eventTourName}
                                                                                                                                                            size="md"
                                                                                                                                                            bg='gray.100'
                                                                                                                                                        />
                                                                                                                                                    </FormControl>
                                                                                                                                                </HStack>
                                                                                                                                            </Box>
                                                                                                                                        </GridItem>
                                                                                                                                    </Grid>
                                                                                                                                </TabPanel>

                                                                                                                                {/* Tab SOURCE */}
                                                                                                                                <TabPanel minH={400}>
                                                                                                                                    <Grid
                                                                                                                                        templateRows='repeat(1, 1fr)'
                                                                                                                                        templateColumns='repeat(5, 1fr)'
                                                                                                                                        gap={5}
                                                                                                                                    >
                                                                                                                                        <GridItem colSpan={5}>
                                                                                                                                            <Box>
                                                                                                                                                <HStack>
                                                                                                                                                    <FormControl w='500px' maxW='50%'>
                                                                                                                                                        <FormLabel htmlFor='sourceType' mt='5' mb='0'>
                                                                                                                                                            {T.events.SourceType}
                                                                                                                                                        </FormLabel>
                                                                                                                                                    </FormControl>
                                                                                                                                                    <FormControl>
                                                                                                                                                        <FormLabel htmlFor='sourceUrl' mt='5' mb='0'>
                                                                                                                                                            {T.events.SourceUrl}
                                                                                                                                                        </FormLabel>
                                                                                                                                                    </FormControl>
                                                                                                                                                </HStack>

                                                                                                                                                {event.source.map((formFieldsSource, index) => {
                                                                                                                                                    return (
                                                                                                                                                        <HStack mt='2' mb='2'>
                                                                                                                                                            <FormControl w='500px' maxW='50%'>
                                                                                                                                                                <Input
                                                                                                                                                                    name='sourceTypeId'
                                                                                                                                                                    placeholder=' '
                                                                                                                                                                    value={formFieldsSource.sourceTypeId._id}
                                                                                                                                                                    size='md'
                                                                                                                                                                    bg='gray.100'
                                                                                                                                                                />
                                                                                                                                                            </FormControl>
                                                                                                                                                            <FormControl>
                                                                                                                                                                <HStack>
                                                                                                                                                                    <Input
                                                                                                                                                                        name='sourceUrl'
                                                                                                                                                                        value={formFieldsSource.sourceUrl}
                                                                                                                                                                        size='md'
                                                                                                                                                                        bg='gray.100'
                                                                                                                                                                    />
                                                                                                                                                                </HStack>
                                                                                                                                                            </FormControl>
                                                                                                                                                        </HStack>
                                                                                                                                                    )
                                                                                                                                                })}
                                                                                                                                            </Box>
                                                                                                                                        </GridItem>
                                                                                                                                    </Grid>
                                                                                                                                </TabPanel>

                                                                                                                            </TabPanels>
                                                                                                                        </Tabs>

                                                                                                                    </TabPanel>



                                                                                                                    {/* Tab STATISTIC */}
                                                                                                                    <TabPanel minH={600}>
                                                                                                                    </TabPanel>

                                                                                                                </TabPanels>
                                                                                                            </Tabs>

                                                                                                        </Box>
                                                                                                    </GridItem>

                                                                                                </Grid>

                                                                                            </Box>

                                                                                        </DrawerBody>
                                                                                    </DrawerContent>
                                                                                </Drawer>
                                                                            </>

                                                                        ))
                                                                    )}
                                                                    {
                                                                        (events && events.length === 0) && (
                                                                            <Tr>
                                                                                <Td colSpan={5} height='50px' textAlign='center'>
                                                                                    <Text color="#777" fontSize={17} fontWeight={400}>- no event found -</Text>
                                                                                </Td>
                                                                            </Tr>
                                                                        )
                                                                    }
                                                                </Tbody>
                                                            </Table>
                                                        </TableContainer>

                                                        <HStack>
                                                            <Box p='2'>
                                                                <Select
                                                                    variant="brandPrimary"
                                                                    id="Pagination"
                                                                    name="Pagination"
                                                                    value={Pagination}
                                                                    onChange={onPaginationChanged}
                                                                >
                                                                    {
                                                                        nbPaginations && nbPaginations.length > 0 && nbPaginations.map((nbPagination, i) => {
                                                                            return (
                                                                                <option
                                                                                    key={i}
                                                                                    value={nbPagination}
                                                                                > {nbPagination}</option >
                                                                            )
                                                                        })
                                                                    }
                                                                </Select>
                                                            </Box>
                                                            <Spacer />
                                                            <Box p='2'>
                                                                <HStack>
                                                                    {paginationContent}
                                                                </HStack>
                                                            </Box>
                                                        </HStack>
                                                    </Box>




                                                </TabPanel>

                                                {/* Tab CUSTOMER */}
                                                <TabPanel minH={400}>
                                                </TabPanel>

                                                {/* Tab VENUE */}
                                                <TabPanel minH={400}>
                                                </TabPanel>

                                                {/* Tab CITY  */}
                                                <TabPanel minH={400}>
                                                </TabPanel>

                                            </TabPanels>
                                        </Tabs>

                                    </form>

                                </Box>
                            </GridItem>

                            <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                                <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">
                                    selectedEventIds = {JSON.stringify(selectedEventIds)} <br />
                                    Page = {Page} <br />
                                    Pagination = {Pagination} <br />
                                </Box>
                            </GridItem>

                        </Grid>
                    </Center >

                </>
            ) : (
                <LoadingScreen section='CLUSTERS' />
            )
            }
        </>
    )

    return content
}

export default EditClusterForm