import { apiSliceOracly } from "../../app/api/apiSliceOracly"

export const oraclyApiSlice = apiSliceOracly.injectEndpoints({
    endpoints: builder => ({
        getMarkets: builder.query({
            query: () => ({
                url: '/markets',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
        }),
        getCountries: builder.query({
            query: () => ({
                url: '/countries',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
        }),
        getCities: builder.query({
            query: () => ({
                url: '/cities',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
        }),
        getVenues: builder.query({
            query: () => ({
                url: '/venues',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
        }),
        getCustomers: builder.query({
            query: () => ({
                url: '/customers',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
        }),
        getTickets: builder.query({
            query: () => ({
                url: '/tickets',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
        }),
        getCategories: builder.query({
            query: () => ({
                url: '/categories',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
        }),
        getEvents: builder.query({
            query: () => ({
                url: '/events',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
        }),
        getEventsFiltered: builder.query({
            query: ({ marketFilter, countryFilter, cityFilter, venueFilter, customerFilter, categoryFilter, eventFilter, eventsInCluster }) => ({
                url: '/events/extract',
                method: 'POST',
                body: { marketFilter, countryFilter, cityFilter, venueFilter, customerFilter, categoryFilter, eventFilter, eventsInCluster },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
        }),
    }),
})

export const {
    useGetMarketsQuery,
    useGetCountriesQuery,
    useGetCitiesQuery,
    useGetVenuesQuery,
    useGetCustomersQuery,
    useGetCategoriesQuery,
    useGetTicketsQuery,
    useGetEventsQuery,
    useGetEventsFilteredQuery,
} = oraclyApiSlice
