import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAddNewClusterMutation } from "./clustersApiSlice"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { css, Box, Grid, GridItem, Center, HStack, VStack, Container, Input, Select, Icon, IconButton, Button, RadioGroup, Radio, Text, useToast, Spacer } from '@chakra-ui/react'
import { FormControl, FormLabel } from "@chakra-ui/react"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { FiShoppingCart, FiMapPin, FiDollarSign, FiCalendar, FiCheckSquare, FiChevronRight, FiPlus, FiMinus, FiList, FiGrid, FiUser, FiUserCheck, FiUserPlus, FiTruck, FiCreditCard, FiCheckCircle } from 'react-icons/fi'
import { ArrowBackIcon, CheckIcon, CloseIcon, AddIcon, MinusIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faCircleXmark, faSquarePlus, faSquareMinus, faGear } from "@fortawesome/free-solid-svg-icons"

const NewClusterForm = () => {

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [addNewCluster, {
        data: dataAdd,
        isLoading: isLoadingAdd,
        isSuccess: isSuccessAdd,
        isError: isErrorAdd,
        error: errorAdd
    }] = useAddNewClusterMutation()

    const navigate = useNavigate()

    let [canSave, setCanSave] = useState(false)

    const clusterLanguagess = [
        { name: 'English', value: 'en' },
        { name: 'French', value: 'fr' },
        { name: 'Spanish', value: 'es' },
        { name: 'Italian', value: 'it' },
        { name: 'Deutch', value: 'de' },
        { name: 'Portuguese', value: 'pt' },
    ]
    const options_clusterLanguages = clusterLanguagess.map(clusterLanguages => {
        return (
            <option
                key={clusterLanguages.value}
                value={clusterLanguages.value}
            >
                {clusterLanguages.name}
            </option>
        )
    })

    const [formFieldsLanguage, setFormFieldsLanguage] = useState([
        { value: 'en' },
    ])

    const handleFormChangeLanguage = (event, index) => {
        let data = [...formFieldsLanguage];
        //data[index][event.target.name] = event.target.value;
        data[index]['value'] = event.target.value;
        setFormFieldsLanguage(data);
        setClusterLanguage(data)
        checkCanSave()
    }

    const addFieldsLanguage = () => {
        let object = {
            value: 'en'
        }
        setFormFieldsLanguage([...formFieldsLanguage, object])
        setClusterLanguage([...formFieldsLanguage, object])
        setCanSave(false)
    }

    const removeFieldsLanguage = (index) => {
        let data = [...formFieldsLanguage];
        data.splice(index, 1)
        setFormFieldsLanguage(data)
        setClusterLanguage(data)
    }

    const [clusterName, setClusterName] = useState()
    const [clusterType, setClusterType] = useState('linker')
    const [clusterLanguages, setClusterLanguage] = useState([{ value: 'en' }])
    const [clusterUrl, setClusterUrl] = useState()
    const [clusterDatabaseURI, setClusterDatabaseURI] = useState()

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    const checkCanSave = () => {
        {
            setCanSave(true)
            if (!isLoadingAdd && (clusterName === '' || clusterType === '')) {
                setCanSave(false)
            }
        }
    }

    const onPageBack = () => navigate('/dash/clusters')

    const onClusterNameChanged = e => setClusterName(e.target.value)

    const onClusterTypeChanged = (value) => {
        setClusterType(value);
        // Faire d'autres traitements selon la valeur sélectionnée
        console.log('Selected value:', value);
    };

    const onClusterUrlChanged = e => setClusterUrl(e.target.value)
    const onClusterDatabaseURIChanged = e => setClusterDatabaseURI(e.target.value)

    const onSaveClusterClicked = async (e) => {
        e.preventDefault()
        console.log("clusterLanguages  = " + JSON.stringify(clusterLanguages))
        console.log("clusterLanguages[0] = " + JSON.stringify(clusterLanguages[0].value))

        const clusterTags = {}
        //Object.entries(clusterLanguages).map((clusterLanguage, index) => {
        //    clusterTags.push({ [clusterLanguages[index].value]: { 'metaTitle': 'title', 'metaDescription': 'description', 'metaKeyword': 'keyword' } })
        //})
        Object.entries(clusterLanguages).forEach(([key, clusterLanguage]) => {
            clusterTags[clusterLanguage.value] = { 'metaTitle': 'title', 'metaDescription': 'description', 'metaKeyword': 'keyword' };
        });
        //const clusterTags = { 'en': { 'metaTitle': 'title', 'metaDescription': 'description', 'metaKeyword': 'keyword' } }
        console.log("clusterTags = " + JSON.stringify(clusterTags))

        if (canSave) {
            await addNewCluster({ clusterName, clusterType, clusterLanguages: formFieldsLanguage, clusterTags, clusterUrl, clusterDatabaseURI })
        }

    }


    useEffect(() => {
        checkCanSave()
        if (isSuccessAdd) {
            toastInfo({ title: 'CLUSTER : ' + dataAdd?.message + ' successfully', status: 'success' })
            navigate('/dash/clusters')
        }
        if (isErrorAdd) {
            toastInfo({ title: 'CLUSTER : Add error (' + errorAdd?.data?.message + ')', status: 'error' })
        }
    }, [isSuccessAdd, isErrorAdd, errorAdd, navigate])

    const content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid w='95vw' h='100vh' maxW='1500' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <HStack color='white'>
                            <Box textAlign={['left']} >
                                <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faUsers} /> {T.clusters.NewCluster}</Text>
                            </Box>
                            <Spacer />
                            <Box p='2' textAlign={['right']}>
                                <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={onSaveClusterClicked}
                                    disabled={!canSave}
                                    leftIcon={<CheckIcon />}
                                    colorScheme={canSave ? "blue" : "gray"}
                                >
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Save}</Text>
                                </Button>
                            </Box>
                        </HStack>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                            <form onSubmit={onSaveClusterClicked}>

                                <Tabs variant='soft-rounded' colorScheme='blue' isFitted>
                                    <TabList
                                        overflowX="auto"
                                        css={css({
                                            scrollbarWidth: 'none',
                                            '::-webkit-scrollbar': { display: 'none' },
                                            '-webkit-overflow-scrolling': 'touch',
                                            boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                            border: '0 none',
                                        })}
                                    >
                                        <Tab _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Cluster</Tab>
                                        <Tab isDisabled _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Database filter</Tab>
                                        <Tab isDisabled _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Content & Metadata</Tab>
                                        <Tab isDisabled _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Link</Tab>
                                        <Tab isDisabled _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}><FontAwesomeIcon icon={faGear} /></Tab>
                                    </TabList>

                                    <TabPanels>

                                        {/* Tab CLUSTER */}
                                        <TabPanel minH={400}>

                                            <HStack>

                                                {/* Left column */}
                                                <FormControl>
                                                    {/* CLUSTER TYPE */}
                                                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "9", xl: "8", "2xl": "8" }}>

                                                        <Text color='black' mb='0' fontSize={18} fontWeight={700}>
                                                            Cluster type
                                                        </Text>
                                                        <RadioGroup defaultValue='linker' value={clusterType} onChange={onClusterTypeChanged}>
                                                            <VStack spacing={5} direction='row'>

                                                                {/* LINKER SITE */}
                                                                <Box
                                                                    p={2}
                                                                    mt='2'
                                                                    w='95%'
                                                                    textAlign={['left']}
                                                                    borderRadius='xl'
                                                                    borderWidth='2px'
                                                                    fontSize={20}
                                                                    fontWeight={400}
                                                                    color='black'
                                                                    bg='#F5F5F5'
                                                                    _hover={{ bg: 'gray.100', color: 'black', borderWidth: '2px', borderColor: '#1B7392' }}
                                                                    isSelected={clusterType === 'linker'}
                                                                    _selected={{ color: 'white', bg: '#990000' }}
                                                                >
                                                                    <Radio p='3' size='lg' bg='#F5F5F5' value='linker' w='100%'>
                                                                        <HStack>
                                                                            <Box ml='5'>
                                                                                <Text fontSize={24} fontWeight={700}>LINKER</Text>
                                                                                <Text fontSize={18} fontWeight={400} color='#1B7392'><Icon as={FiChevronRight} h={4} w={4} mr={1} alignSelf={'center'} />is an aggregator of links sources to ticketing websites.</Text>
                                                                                <Text fontSize={15} fontWeight={500}>ASSETS: markets, countries, cities, venues, customers, categories, events.</Text>
                                                                            </Box>
                                                                        </HStack>
                                                                    </Radio>
                                                                </Box>

                                                                {/* SHOP SITE */}
                                                                <Box
                                                                    p={2}
                                                                    mt='2'
                                                                    w='95%'
                                                                    textAlign={['left']}
                                                                    borderRadius='xl'
                                                                    borderWidth='2px'
                                                                    fontSize={20}
                                                                    fontWeight={400}
                                                                    color='black'
                                                                    bg='#F5F5F5'
                                                                    _hover={{ bg: 'gray.100', color: 'black', borderWidth: '2px', borderColor: '#1B7392' }}
                                                                    isSelected={clusterType === 'shop'}
                                                                    _selected={{ color: 'white', bg: '#990000' }}
                                                                >
                                                                    <Radio p='3' size='lg' bg='#F5F5F5' value='shop' w='100%'>
                                                                        <HStack>
                                                                            <Box ml='5'>
                                                                                <Text fontSize={24} fontWeight={700}>SHOP</Text>
                                                                                <Text fontSize={18} fontWeight={400} color='#1B7392'><Icon as={FiChevronRight} h={4} w={4} mr={1} alignSelf={'center'} />is an ecommerce store dedicated to ticket sales.</Text>
                                                                                <Text fontSize={15} fontWeight={500}>ASSETS: markets, countries, cities, venues, customers, categories, events, maps, tickets.</Text>
                                                                            </Box>
                                                                        </HStack>
                                                                    </Radio>
                                                                </Box>

                                                                {/* MARKETPLACE SITE */}
                                                                <Box
                                                                    p={2}
                                                                    mt='2'
                                                                    w='95%'
                                                                    textAlign={['left']}
                                                                    borderRadius='xl'
                                                                    borderWidth='2px'
                                                                    fontSize={20}
                                                                    fontWeight={400}
                                                                    color='black'
                                                                    bg='#F5F5F5'
                                                                    _hover={{ bg: 'gray.100', color: 'black', borderWidth: '2px', borderColor: '#1B7392' }}
                                                                    isSelected={clusterType === 'marketplace'}
                                                                    _selected={{ color: 'white', bg: '#990000' }}
                                                                >
                                                                    <Radio p='3' size='lg' bg='#F5F5F5' value='marketplace' w='100%'>
                                                                        <HStack>
                                                                            <Box ml='5'>
                                                                                <Text fontSize={24} fontWeight={700}>MARKETPLACE</Text>
                                                                                <Text fontSize={18} fontWeight={400} color='#1B7392'><Icon as={FiChevronRight} h={4} w={4} mr={1} alignSelf={'center'} />is a multi-seller ticket sales marketplace.</Text>
                                                                                <Text fontSize={15} fontWeight={500}>ASSETS: markets, countries, cities, venues, customers, categories, events, maps, tickets.</Text>
                                                                            </Box>
                                                                        </HStack>
                                                                    </Radio>
                                                                </Box>

                                                                {/* TOOL SITE */}
                                                                <Box
                                                                    p={2}
                                                                    mt='2'
                                                                    w='95%'
                                                                    textAlign={['left']}
                                                                    borderRadius='xl'
                                                                    borderWidth='2px'
                                                                    fontSize={20}
                                                                    fontWeight={400}
                                                                    color='black'
                                                                    bg='#F5F5F5'
                                                                    isSelected={clusterType === 'tool'}
                                                                    _selected={{ color: 'white', bg: '#990000' }}
                                                                    _hover={{ bg: 'gray.100', color: 'black', borderWidth: '2px', borderColor: '#1B7392' }}
                                                                >
                                                                    <Radio p='3' size='lg' bg='#F5F5F5' value='tool' w='100%'>
                                                                        <HStack>
                                                                            <Box ml='5'>
                                                                                <Text fontSize={24} fontWeight={700}>TOOL</Text>
                                                                                <Text fontSize={18} fontWeight={400} color='#1B7392'><Icon as={FiChevronRight} h={4} w={4} mr={1} alignSelf={'center'} />is a tool dedicated to ticketing.</Text>
                                                                                <Text fontSize={15} fontWeight={500}>ASSETS: markets, countries, cities, venues, customers, categories, events.</Text>
                                                                            </Box>
                                                                        </HStack>
                                                                    </Radio>
                                                                </Box>

                                                            </VStack>
                                                        </RadioGroup>

                                                    </GridItem>
                                                </FormControl>

                                                {/* Right column */}
                                                <FormControl>

                                                    <FormLabel htmlFor='clusterName' mt='5' mb='0'>
                                                        <Text color='black' mb='0' fontSize={18} fontWeight={700}>
                                                            {T.clusters.ClusterName}
                                                        </Text>
                                                    </FormLabel>
                                                    <Input
                                                        isInvalid={!clusterName}
                                                        id="clusterName"
                                                        name="clusterName"
                                                        value={clusterName}
                                                        onChange={onClusterNameChanged}
                                                        bg='yellow.100'
                                                    />

                                                    <FormLabel htmlFor='clusterLanguages' mt='5' mb='0'>
                                                        <Text color='black' mb='0' fontSize={18} fontWeight={700}>
                                                            {T.clusters.ClusterLanguage}
                                                        </Text>
                                                    </FormLabel>
                                                    {formFieldsLanguage.map((form, index) => {
                                                        return (
                                                            <HStack key={index}>

                                                                <Select
                                                                    isInvalid={!clusterLanguages}
                                                                    id="clusterLanguages"
                                                                    name="clusterLanguages"
                                                                    value={form.value}
                                                                    onChange={event => handleFormChangeLanguage(event, index)}
                                                                    bg='yellow.100'
                                                                >
                                                                    {options_clusterLanguages}
                                                                </Select>
                                                                {index !== 0 &&
                                                                    <IconButton
                                                                        size='xs'
                                                                        name="Remove"
                                                                        title={T.common.Delete}
                                                                        colorScheme='gray'
                                                                        aria-label={T.common.Delete}
                                                                        icon={<MinusIcon />}
                                                                        onClick={() => removeFieldsLanguage(index)}
                                                                    />
                                                                }
                                                            </HStack>
                                                        )
                                                    })}
                                                    <IconButton
                                                        size='xs'
                                                        title={T.common.Add}
                                                        onClick={addFieldsLanguage}
                                                        colorScheme='gray'
                                                        aria-label={T.common.Add}
                                                        icon={<AddIcon />}
                                                    />

                                                    <FormLabel htmlFor='clusterUrl' mt='5' mb='0'>
                                                        <Text color='black' mb='0' fontSize={18} fontWeight={700}>
                                                            {T.clusters.ClusterUrl}
                                                        </Text>
                                                    </FormLabel>
                                                    <Input
                                                        isInvalid={!clusterUrl}
                                                        id="clusterUrl"
                                                        name="clusterUrl"
                                                        value={clusterUrl}
                                                        onChange={onClusterUrlChanged}
                                                        bg='yellow.100'
                                                    />

                                                    <FormLabel htmlFor='clusterDatabaseURI' mt='5' mb='0'>
                                                        <Text color='black' mb='0' fontSize={18} fontWeight={700}>
                                                            {T.clusters.ClusterDatabaseURI}
                                                        </Text>
                                                    </FormLabel>
                                                    <Input
                                                        isInvalid={!clusterDatabaseURI}
                                                        id="clusterDatabaseURI"
                                                        name="clusterDatabaseURI"
                                                        value={clusterDatabaseURI}
                                                        onChange={onClusterDatabaseURIChanged}
                                                        bg='yellow.100'
                                                    />
                                                </FormControl>

                                            </HStack>

                                        </TabPanel>

                                        {/* Tab DATABASE */}
                                        <TabPanel minH={400}>
                                        </TabPanel>

                                        {/* Tab SETUP */}
                                        <TabPanel minH={400}>
                                        </TabPanel>

                                    </TabPanels>
                                </Tabs>
                            </form>

                        </Box>
                    </GridItem>

                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                            <br />
                            clusterType = {clusterType}

                            <br />
                            clusterLanguages = {JSON.stringify(clusterLanguages)}

                        </Box>
                    </GridItem>

                </Grid>
            </Center>
        </>
    )

    return content
}

export default NewClusterForm