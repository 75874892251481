import { useState, useEffect } from "react"
import { useUpdateClusterMutation, useDeleteClusterMutation } from "./clustersApiSlice"
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `chakra-react-select` component
import {
    //AsyncCreatableSelect,
    //AsyncSelect,
    //CreatableSelect,
    Select as Select2,
} from "chakra-react-select";

// import `ChakraProvider` component
import { css, Box, Grid, GridItem, Center, Icon, IconButton, HStack, VStack, RadioGroup, Radio, Tag, Spacer, Textarea, Select, Checkbox, Input, Switch, Button, Text, useToast, SimpleGrid } from '@chakra-ui/react'
import { FormControl, FormLabel } from "@chakra-ui/react"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { FiShoppingCart, FiMapPin, FiDollarSign, FiCalendar, FiCheckSquare, FiChevronRight, FiPlus, FiMinus, FiList, FiGrid, FiUser, FiUserCheck, FiUserPlus, FiTruck, FiCreditCard, FiCheckCircle } from 'react-icons/fi'
import { ArrowBackIcon, CheckIcon, CloseIcon, AddIcon, MinusIcon, ArrowForwardIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faGear } from "@fortawesome/free-solid-svg-icons"

const EditClusterForm = ({ cluster, markets, countries, cities, venues, customers, categories, events }) => {

    const { isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [updateCluster, {
        data: dataUp,
        isLoading: isLoadingUp,
        isSuccess: isSuccessUp,
        isError: isErrorUp,
        error: errorUp
    }] = useUpdateClusterMutation()

    const [deleteCluster, {
        data: dataDel,
        isSuccess: isSuccessDel,
        isError: isErrorDel,
        error: errorDel
    }] = useDeleteClusterMutation()

    const navigate = useNavigate()

    let [canSave, setCanSave] = useState(false)

    const [clusterName, setClusterName] = useState(cluster.clusterName)
    const [clusterType, setClusterType] = useState(cluster.clusterType)
    //const [selectedValue, setSelectedValue] = useState(cluster.clusterType);

    const [clusterLanguage, setClusterLanguage] = useState(cluster.clusterLanguages)
    const [clusterTag, setClusterTag] = useState(cluster.clusterTags)
    const [clusterUrl, setClusterUrl] = useState(cluster.clusterUrl)
    const [clusterDatabaseURI, setClusterDatabaseURI] = useState(cluster.clusterDatabaseURI)

    const [clusterStatus, setClusterStatus] = useState(cluster.clusterStatus)

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    const checkCanSave = () => {
        setCanSave(true)
        if ((clusterType === '')) {
            setCanSave(false)
        }
        formFieldsClusterName.map((form) => {
            if (!isLoadingUp && (form.lang === '' || form.clusterName === '')) {
                setCanSave(false)
            }
        })
    }

    const onPageBack = () => navigate('/dash/clusters')

    const onClusterNameChanged = e => setClusterName(e.target.value)
    //const onClusterTypeChanged = e => setClusterType(e.target.value)

    const onClusterTypeChanged = (value) => {
        setClusterType(value);
        // Faire d'autres traitements selon la valeur sélectionnée
        console.log('Selected value:', value);
    };

    const onClusterLanguageChanged = e => setClusterLanguage(e.target.value)
    const onClusterUrlChanged = e => setClusterUrl(e.target.value)
    const onClusterDatabaseURIChanged = e => setClusterDatabaseURI(e.target.value)
    const onClusterStatusChanged = e => setClusterStatus(prev => !prev)

    const onSaveClusterClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            /*
            const dataMarket = optMarket.map(option => option.value);
            const dataCountry = optCountry.map(option => option.value);
            const dataCity = optCity.map(option => option.value);
            const dataVenue = optVenue.map(option => option.value);
            const dataCustomer = optCustomer.map(option => option.value);
            const dataCategory = optCategory.map(option => option.value);
            const dataEvent = optEvent.map(option => option.value);
            const clusterDataFilter = { dataMarket, dataCountry, dataCity, dataCustomer, dataCategory, dataEvent }
            */
            const clusterDataFilter = { filterMarket: optMarket, filterCountry: optCountry, filterCity: optCity, filterVenue: optVenue, filterCustomer: optCustomer, filterCategory: optCategory, filterEvent: optEvent }

            console.log('formFieldsTags === ' + JSON.stringify(formFieldsTags))

            // Utilisation de reduce pour combiner les objets du tableau en un seul objet
            const formFieldsTagsObj = formFieldsTags.reduce((acc, current) => {
                // Récupère la clé (par exemple, "en" ou "fr")
                const key = Object.keys(current)[0];

                // Ajoute la paire clé-valeur à l'accumulateur
                acc[key] = current[key];

                return acc;
            }, {});
            console.log('formFieldsTagsObj === ' + JSON.stringify(formFieldsTagsObj))

            await updateCluster({ clusterId: cluster._id, clusterName, clusterType, clusterLanguages: formFieldsLanguages, clusterTags: formFieldsTagsObj, clusterUrl, clusterDatabaseURI, clusterDataFilter, clusterStatus })
        }
    }

    const onDeleteClusterClicked = async () => {
        await deleteCluster({ id: cluster.id })
    }

    const created = new Date(cluster.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    const updated = new Date(cluster.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    const clusterTypes = [{ name: 'Tool' }, { name: 'Website' }, { name: 'Linker' }, { name: 'Shop' }, { name: 'Marketplace' }]
    const options_clusterType = clusterTypes.map(type => {
        return (
            <option
                key={type.name}
                value={type.name}
            >
                {type.name}
            </option>
        )
    })

    const clusterLanguages = [
        { name: 'English', value: 'en' },
        { name: 'French', value: 'fr' },
        { name: 'Spanish', value: 'es' },
        { name: 'Italian', value: 'it' },
        { name: 'Deutch', value: 'de' },
        { name: 'Portuguese', value: 'pt' },
    ]
    const options_clusterLanguage = clusterLanguages.map(clusterLanguage => {
        return (
            <option
                key={clusterLanguage.value}
                value={clusterLanguage.value}
            >
                {clusterLanguage.name}
            </option>
        )
    })

    const [formFieldsLanguages, setFormFieldsLanguages] = useState(cluster.clusterLanguages)
    const handleFormChangeLanguage = (event, index) => {
        let data = [...formFieldsLanguages];
        //data[index][event.target.name] = event.target.value;
        data[index]['value'] = event.target.value;
        setFormFieldsLanguages(data);
        checkCanSave()
    }
    const addFieldsLanguage = () => {
        let object = {
            value: 'en'
        }
        setFormFieldsLanguages([...formFieldsLanguages, object])
        setCanSave(false)
    }
    const removeFieldsLanguage = (index) => {
        let dataLang = [...formFieldsLanguages];
        dataLang.splice(index, 1)
        setFormFieldsLanguages(dataLang)

        let dataTag = [...formFieldsTags];
        dataTag.splice(index, 1)
        setFormFieldsTags(dataTag)
    }


    const initClusterTag = () => {
        let formFieldsTags = []
        Object.entries(cluster.clusterTags).map(item => {
            formFieldsTags.push({ [item[0]]: item[1] })
        })
        console.log("formFieldsTags == " + JSON.stringify(formFieldsTags))
        return formFieldsTags
    }

    const [formFieldsTags, setFormFieldsTags] = useState(initClusterTag)
    //const [formFieldsTags, setFormFieldsTags] = useState(cluster.clusterTag)

    const handleFormChangeTag = (event, index) => {
        let data = [...formFieldsTags];
        //data[index][event.target.name] = event.target.value;
        data[index]['value'] = event.target.value;
        setFormFieldsTags(data);
        checkCanSave()
    }

    const addFieldsTag = (indexLang) => {
        //alert("indexLang = " + indexLang)
        //alert("formFieldsTags = " + JSON.stringify(formFieldsTags))

        //console.log("indexLang = " + JSON.stringify(indexLang))
        console.log("indexLang = " + JSON.stringify(indexLang))
        console.log("formFieldsTags[indexLang] = " + JSON.stringify(formFieldsTags[indexLang]))
        //console.log("formFieldsTags[0]['en'] = " + JSON.stringify(formFieldsTags[0]['en']))
        console.log("formFieldsTags[indexLang] = " + JSON.stringify(formFieldsTags[indexLang]))

        if (formFieldsTags[indexLang] == undefined) {

            alert("indexLang = " + JSON.stringify(indexLang))

            alert("formFieldsLanguages = " + JSON.stringify(formFieldsLanguages))
            alert("formFieldsLanguages[indexLang].value = " + JSON.stringify(formFieldsLanguages[indexLang].value))

            alert("formFieldsTags = " + JSON.stringify(formFieldsTags))

            let data = [...formFieldsTags];
            // Vérifiez si indexLang est dans la plage valide
            if (indexLang >= 0 && indexLang < data.length) {
                // Si l'index existe, ajoutez la clé 'es' avec des valeurs spécifiques
                data[indexLang][formFieldsLanguages[indexLang].value] = { "metaTitle": "title", "metaDescription": "description", "metaKeyword": "keyword" };
            } else {
                // Si l'index n'existe pas, ajoutez-le avec la clé 'es' et ses valeurs spécifiques
                data[indexLang] = { [formFieldsLanguages[indexLang].value]: { "metaTitle": "title", "metaDescription": "description", "metaKeyword": "keyword" } };
            }

            setFormFieldsTags(data);

            console.log("data = " + JSON.stringify(data))
            alert("formFieldsTags = " + JSON.stringify(formFieldsTags))


        } else {
            setFormFieldsTags(prevFormFieldsTags => {
                return prevFormFieldsTags.map((languageObj, index) => {

                    alert("languageObj = " + JSON.stringify(languageObj))
                    //if (formFieldsTags[indexLang]) {
                    const languageKey = Object.keys(languageObj);
                    const metaData = languageObj[languageKey];

                    alert("languageKey = " + JSON.stringify(languageKey))
                    alert("metaData = " + JSON.stringify(metaData))

                    // Vérifiez si l'index correspond à l'indexLang, puis mettez à jour l'objet existant
                    if (index === indexLang) {
                        const updatedObject = { [languageKey]: { ...metaData, varTag: "NouvelleValeurMetaTitle2" } };
                        return updatedObject;
                    }
                    return languageObj;


                    { /*
                     else {
                        alert("formFieldsLanguages = " + JSON.stringify(formFieldsLanguages[indexLang].value))
                        return prevFormFieldsTags.map((languageObj, index) => {
                            const languageKey = formFieldsLanguages[indexLang].value;
                            const metaData = { "metaTitle": "title", "metaDescription": "description", "metaKeyword": "keyword" };
    
                            // Vérifiez si l'index correspond à l'indexLang, puis mettez à jour l'objet existant
                            const updatedObject = { [languageKey]: [{ ...metaData, varTag: "NouvelleValeurMetaTitle2" }] };
    
                            alert("updatedObject = " + JSON.stringify(updatedObject))
                            return updatedObject;
    
                        });
    
                    }
                    */}

                });

            });
        }


    };

    const addFieldsTag2 = (indexLang) => {
        let data = [...formFieldsTags];

        let object = {
            tagName1: 'tagValue1',
            tagName2: 'tagValue2',
        }
        setFormFieldsTags([...formFieldsTags[0], object])
        setCanSave(false)
    }
    const removeFieldsTag2 = (indexTag) => {
        setFormFieldsTags(prevFormFieldsTags => {
            const newData = [...prevFormFieldsTags];
            newData.splice(indexTag, 1);
            return newData;
        });
    };

    const removeFieldsTag = (indexLang, indexTag) => {
        setFormFieldsTags(prevFormFieldsTags => {
            // Vérifier si l'indexLang est valide
            if (indexLang >= 0 && indexLang < prevFormFieldsTags.length) {
                const languageObj = prevFormFieldsTags[indexLang];
                const languageKey = Object.keys(languageObj)[0];

                console.log('indexLang = ' + JSON.stringify(indexLang))
                console.log('indexTag = ' + JSON.stringify(indexTag))
                console.log('languageKey = ' + JSON.stringify(languageKey))
                console.log('languageObj = ' + JSON.stringify(languageObj))

                //console.log('languageObj.languageKey = ' + JSON.stringify(languageObj[languageKey]))
                //console.log('languageObj.languageKey[indexTag] = ' + JSON.stringify(languageObj[languageKey]))

                // Vérifier si la clé "fr" existe
                if (languageObj && languageObj[languageKey]) {
                    // Créer une copie de l'objet sans la clé "varTag2"
                    let updatedObj = { ...languageObj };
                    //delete updatedObj.fr[0].varTag;
                    //console.log('indexTag = ' + JSON.stringify(indexTag))

                    //console.log('updatedObj[indexLang] = ' + JSON.stringify(updatedObj[indexLang]))
                    //console.log('updatedObj[languageKey] = ' + JSON.stringify(updatedObj[languageKey]))

                    const tagKey = Object.keys(updatedObj[languageKey])[indexTag];
                    //console.log('updatedObj = ' + JSON.stringify(updatedObj))
                    console.log('tagKey = ' + JSON.stringify(tagKey))

                    //delete updatedObj[indexLang][0][indexTag];
                    //console.log('updatedObj[languageKey] = ' + JSON.stringify(updatedObj[languageKey]))
                    console.log('updatedObj[languageKey][tagKey] = ' + JSON.stringify(updatedObj[languageKey][tagKey]))

                    // Vérifiez si la propriété existe avant de la supprimer
                    if (updatedObj[languageKey] && updatedObj[languageKey].hasOwnProperty(tagKey)) {
                        //if (updatedObj["de"] && updatedObj["de"].hasOwnProperty(tagKey) && Object.getOwnPropertyDescriptor(updatedObj[languageKey], tagKey).configurable) {

                        //delete updatedObj[languageKey]['varTag'];

                        // Créez un nouvel objet sans la propriété à supprimer
                        updatedObj = Object.keys(updatedObj[languageKey]).reduce((acc, key) => {
                            if (key !== tagKey) {
                                acc[key] = updatedObj[languageKey][key];
                            }
                            return acc;
                        }, {});

                        console.log('updatedObj = ' + JSON.stringify(updatedObj))

                        //console.log('updatedObj[languageKey][tagKey] = ' + JSON.stringify(updatedObj[languageKey][tagKey]))
                        console.log('updatedObj[languageKey] = ' + JSON.stringify(updatedObj[languageKey]))
                        console.log(`La propriété "${tagKey}" a été supprimée avec succès.`);
                    } else {
                        console.log(`La propriété "${tagKey}" n'existe pas dans l'objet.`);
                    }

                    //delete updatedObj[languageKey][tagKey];
                    //                    delete updatedObj[languageKey];
                    console.log('updatedObj = ' + JSON.stringify(updatedObj))

                    // Créer une nouvelle copie du tableau d'origine avec l'objet mis à jour
                    const updatedArray = [...prevFormFieldsTags];
                    updatedArray[indexLang] = { [languageKey]: updatedObj };

                    return updatedArray;
                }
            }

            // Aucune mise à jour nécessaire si les conditions ne sont pas remplies
            console.log('formFieldsTags = ' + JSON.stringify(formFieldsTags))
            return prevFormFieldsTags;
        });
    }

    const removeFieldsTag3 = (indexLang, indexTag) => {
        console.log('indexLang = ' + indexLang + ' | indexTag = ' + indexTag)
        //setFormFieldsTags(prevFormFieldsTags => {
        return formFieldsTags.map((formFieldsTag, index) => {

            console.log('languageObj = ' + JSON.stringify(formFieldsTag))

            if (index === indexLang) {
                const languageKey = Object.keys(formFieldsTag)[0];
                console.log('languageKey = ' + JSON.stringify(languageKey))

                // Créer une copie du tableau
                const updatedArray = [...formFieldsTags];
                console.log('index = ' + JSON.stringify(index))
                console.log('updatedArray[index] = ' + JSON.stringify(updatedArray[index][languageKey][0]))
                //console.log('updatedArray[0].languageKey = ' + JSON.stringify(updatedArray[0][languageKey][0]))

                const tagKey = Object.keys(updatedArray[index][languageKey][0])[indexTag];
                console.log('updatedArray = ' + JSON.stringify(updatedArray))
                console.log('tagKey = ' + JSON.stringify(tagKey))

                delete updatedArray[index][languageKey][0].tagKey

                //updatedArray = Object.keys(updatedArray[index][languageKey][0]).splice(tagKey, 1);
                console.log('updatedArray = ' + JSON.stringify(updatedArray))


            }

            /*
            // Vérifier si la clé de langue existe et si le tagKey existe dans le sous-tableau
            if (updatedArray[indexLang] && updatedArray[indexLang][0][indexTag]) {
                // Supprimer l'élément à l'index spécifié dans le sous-tableau
                updatedArray[indexLang][0].splice(indexTag, 1);
            }
            console.log('updatedArray = ' + JSON.stringify(updatedArray))
            */

            /*
            // Vérifiez si l'index correspond à l'indexLang
            if (index === indexLang) {
                console.log('indexLang = ' + indexLang)
                console.log('indexTag = ' + indexTag)
                console.log('languageKey = ' + JSON.stringify(languageKey))
                console.log('languageObj = ' + JSON.stringify(languageObj))
    
                // Supprimez l'objet à l'indexTag dans le sous-tableau correspondant à la langue
                const updatedArray = languageObj[languageKey].filter((_, i) => i !== indexTag);
                console.log('languageObj[languageKey] = ' + JSON.stringify(languageObj[languageKey]))
                console.log('updatedArray = ' + JSON.stringify(updatedArray))
                //                const updatedArray2 = languageObj.splice(indexTag, 1);
                //                console.log('updatedArray2 = ' + JSON.stringify(updatedArray2))
    
                return { [languageKey]: updatedArray };
            }
            */

            /*
            // Vérifiez si l'index correspond à l'indexLang
            if (index === indexTag) {
                // Supprimer la clé spécifiée du sous-tableau par son index
                console.log('languageKey = ' + JSON.stringify(languageKey))
                console.log('languageObj[languageKey] = ' + JSON.stringify(languageObj[languageKey]))
                //const updatedArray = languageObj[languageKey].filter((_, i) => i !== indexTag);
    
    
                const updatedArray = languageObj[languageKey].filter((item, i) => {
                    console.log('item = ' + JSON.stringify(item))
    
                    // Créer une copie du tableau
                    const newArray = [...item];
                    // Supprimer l'élément à l'index spécifié
                    newArray.splice(indexTag, 1);
                    console.log('newArray = ' + JSON.stringify(newArray))
    
                    console.log('i = ' + JSON.stringify(i))
                    console.log('indexTag = ' + JSON.stringify(indexTag))
                    // Supprime l'élément si l'index correspond à celui que vous souhaitez supprimer
                    return i !== indexTag;
                });
                console.log('updatedArray = ' + JSON.stringify(updatedArray))
                return { [languageKey]: updatedArray };
            }
            */

            return formFieldsTag;
        });
        //});
    };



    const marketOptions = (markets ?? []).map(({ _id, marketName }) => ({
        value: _id,
        label: marketName[lang]
    }));

    const countryOptions = (countries ?? []).map(({ _id, countryName }) => ({
        value: _id,
        label: countryName[lang]
    }));

    const cityOptions = (cities ?? []).map(({ _id, cityName }) => ({
        value: _id,
        label: cityName[lang]
    }));

    const venueOptions = (venues ?? []).map(({ _id, venueName }) => ({
        value: _id,
        label: venueName[lang]
    }));

    const customerOptions = (customers ?? []).map(({ _id, customerName }) => ({
        value: _id,
        label: customerName[lang]
    }));

    const categoryOptions = (categories ?? []).map(({ _id, categoryName }) => ({
        value: _id,
        label: categoryName[lang]
    }));

    const eventOptions = (events ?? []).map(({ _id, event, venue }) => ({
        value: _id,
        label: event.eventName + ' (' + event.eventDateStart + ')',
        eventName: event.eventName,
        eventDateStart: event.eventDateStart,
        eventHourStart: event.eventHourStart,
        venueName: venue.venueName,
        cityName: venue.cityName,
        countryName: venue.countryName,
        marketName: venue.marketName,
    }));




    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <Button size='xs' onClick={onDeleteClusterClicked} name="Delete" title={T.common.Delete} leftIcon={<CloseIcon />} colorScheme='red' variant='outline'>{T.clusters.DeleteThisCluster}</Button>
        )
    }

    const initClusterNamesForm = () => {
        let clusterNameByLang = []
        Object.entries(cluster.clusterName).map(item => {
            clusterNameByLang.push({ lang: item[0], clusterName: item[1] })
        })
        return clusterNameByLang
    }

    const [formFieldsClusterName, setFormFields] = useState(initClusterNamesForm)
    // État pour stocker la valeur du checkbox optMarket
    const [optMarket, setOptMarket] = useState(cluster.clusterDataFilter[0].filterMarket || []);
    const onSelectMarketOptChanged = selectedOptions => { setOptMarket(selectedOptions); };
    const [isChecked_optMarket, setIsChecked_optMarket] = useState(optMarket === null || optMarket.length === 0 ? true : false);
    const handleCheckboxChange_optMarket = () => {
        setIsChecked_optMarket(!isChecked_optMarket); // Inverse la valeur actuelle (true devient false et vice versa)
        setOptMarket(isChecked_optMarket ? optMarket : []);
    };

    // État pour stocker la valeur du checkbox optCountry
    const [optCountry, setOptCountry] = useState(cluster.clusterDataFilter[0].filterCountry || []);
    const onSelectCountryOptChanged = selectedOptions => { setOptCountry(selectedOptions); };
    const [isChecked_optCountry, setIsChecked_optCountry] = useState(optCountry === null || optCountry.length === 0 ? true : false);
    // Fonction de gestion d'événement pour mettre à jour l'état lorsqu'il y a un changement de valeur du checkbox
    const handleCheckboxChange_optCountry = () => {
        setIsChecked_optCountry(!isChecked_optCountry); // Inverse la valeur actuelle (true devient false et vice versa)
        setOptCountry(isChecked_optCountry ? optCountry : []);
    };

    // État pour stocker la valeur du checkbox optCity
    const [optCity, setOptCity] = useState(cluster.clusterDataFilter[0].filterCity || []);
    const onSelectCityOptChanged = selectedOptions => { setOptCity(selectedOptions); };
    const [isChecked_optCity, setIsChecked_optCity] = useState(optCity === null || optCity.length === 0 ? true : false);
    // Fonction de gestion d'événement pour mettre à jour l'état lorsqu'il y a un changement de valeur du checkbox
    const handleCheckboxChange_optCity = () => {
        setIsChecked_optCity(!isChecked_optCity); // Inverse la valeur actuelle (true devient false et vice versa)
        setOptCity(isChecked_optCity ? optCity : []);
    };

    // État pour stocker la valeur du checkbox optVenue
    const [optVenue, setOptVenue] = useState(cluster.clusterDataFilter[0].filterVenue || []);
    const onSelectVenueOptChanged = selectedOptions => { setOptVenue(selectedOptions); };
    const [isChecked_optVenue, setIsChecked_optVenue] = useState(optVenue === null || optVenue.length === 0 ? true : false);
    // Fonction de gestion d'événement pour mettre à jour l'état lorsqu'il y a un changement de valeur du checkbox
    const handleCheckboxChange_optVenue = () => {
        setIsChecked_optVenue(!isChecked_optVenue); // Inverse la valeur actuelle (true devient false et vice versa)
        setOptVenue(isChecked_optVenue ? optVenue : []);
    };

    // État pour stocker la valeur du checkbox optCustomer
    const [optCustomer, setOptCustomer] = useState(cluster.clusterDataFilter[0].filterCustomer || []);
    const onSelectCustomerOptChanged = selectedOptions => { setOptCustomer(selectedOptions); };
    const [isChecked_optCustomer, setIsChecked_optCustomer] = useState(optCustomer === null || optCustomer.length === 0 ? true : false);
    // Fonction de gestion d'événement pour mettre à jour l'état lorsqu'il y a un changement de valeur du checkbox
    const handleCheckboxChange_optCustomer = () => {
        setIsChecked_optCustomer(!isChecked_optCustomer); // Inverse la valeur actuelle (true devient false et vice versa)
        setOptCustomer(isChecked_optCustomer ? optCustomer : []);
    };

    // État pour stocker la valeur du checkbox optCategory
    const [optCategory, setOptCategory] = useState(cluster.clusterDataFilter[0].filterCategory || []);
    const onSelectCategoryOptChanged = selectedOptions => { setOptCategory(selectedOptions); };
    const [isChecked_optCategory, setIsChecked_optCategory] = useState(optCategory === null || optCategory.length === 0 ? true : false);
    // Fonction de gestion d'événement pour mettre à jour l'état lorsqu'il y a un changement de valeur du checkbox
    const handleCheckboxChange_optCategory = () => {
        setIsChecked_optCategory(!isChecked_optCategory); // Inverse la valeur actuelle (true devient false et vice versa)
        setOptCategory(isChecked_optCategory ? optCategory : []);
    };

    // État pour stocker la valeur du checkbox optEvent
    const [optEvent, setOptEvent] = useState(cluster.clusterDataFilter[0].filterEvent || []);
    const onSelectEventOptChanged = selectedOptions => { setOptEvent(selectedOptions); };
    const [isChecked_optEvent, setIsChecked_optEvent] = useState(optEvent === null || optEvent.length === 0 ? true : false);
    // Fonction de gestion d'événement pour mettre à jour l'état lorsqu'il y a un changement de valeur du checkbox
    const handleCheckboxChange_optEvent = () => {
        setIsChecked_optEvent(!isChecked_optEvent); // Inverse la valeur actuelle (true devient false et vice versa)
        setOptEvent(isChecked_optEvent ? optEvent : []);
    };



    useEffect(() => {
        checkCanSave()
        if (isSuccessUp) {
            toastInfo({ title: 'CLUSTER : ' + dataUp?.message + ' successfully', status: 'success' })
            initClusterNamesForm()
            navigate('/dash/clusters')
        }
        if (isErrorUp) {
            toastInfo({ title: 'CLUSTER : Update error (' + errorUp?.message + ')', status: 'error' })
        }
        if (isSuccessDel) {
            toastInfo({ title: 'CLUSTER : ' + dataDel?.message + ' successfully', status: 'success' })
            initClusterNamesForm()
            navigate('/dash/clusters')
        }
        if (isErrorDel) {
            toastInfo({ title: 'CLUSTER : Update error (' + errorDel?.message + ')', status: 'error' })
        }
    }, [checkCanSave, initClusterNamesForm, toastInfo, dataUp, isSuccessUp, isErrorUp, errorUp, dataDel, isSuccessDel, isErrorDel, errorDel, navigate])

    const content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid w='95vw' maxW='1500' minH='100vh' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <HStack color='white'>
                            <Box textAlign={['left']} >
                                <HStack>
                                    <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faUsers} /> {T.clusters.EditCluster}</Text>
                                    <Tag m='5' p='3'>
                                        <VStack>
                                            <Text fontSize={22} fontWeight={600}>
                                                {clusterName}
                                            </Text>
                                        </VStack>
                                    </Tag>
                                </HStack>
                            </Box>
                            <Spacer />
                            <Box p='2' textAlign={['right']}>
                                <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={onSaveClusterClicked}
                                    disabled={!canSave}
                                    leftIcon={<CheckIcon />}
                                    colorScheme={canSave ? "blue" : "gray"}
                                >
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Save}</Text>
                                </Button>
                            </Box>
                        </HStack>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                            <form onSubmit={e => e.preventDefault()}>

                                <Tabs variant='soft-rounded' colorScheme='blue' isFitted>
                                    <TabList
                                        overflowX="auto"
                                        css={css({
                                            scrollbarWidth: 'none',
                                            '::-webkit-scrollbar': { display: 'none' },
                                            '-webkit-overflow-scrolling': 'touch',
                                            boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                            border: '0 none',
                                        })}
                                    >
                                        <Tab _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Cluster</Tab>
                                        <Tab _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Database filter</Tab>
                                        <Tab _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Content & Metadata</Tab>
                                        <Tab _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Link</Tab>
                                        <Tab _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}><FontAwesomeIcon icon={faGear} /></Tab>
                                    </TabList>

                                    <TabPanels>
                                        {/* Tab CLUSTER */}
                                        <TabPanel minH={400}>

                                            <HStack>

                                                {/* Left column */}
                                                <FormControl>
                                                    {/* CLUSTER TYPE */}
                                                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "9", xl: "8", "2xl": "8" }}>

                                                        <Text color='black' mb='0' fontSize={18} fontWeight={700}>
                                                            Cluster type :
                                                        </Text>
                                                        <RadioGroup defaultValue='linker' value={clusterType} onChange={onClusterTypeChanged}>
                                                            <VStack spacing={5} direction='row'>

                                                                {/* LINKER SITE */}
                                                                <Box
                                                                    p={2}
                                                                    mt='2'
                                                                    w='95%'
                                                                    textAlign={['left']}
                                                                    borderRadius='xl'
                                                                    borderWidth='2px'
                                                                    fontSize={20}
                                                                    fontWeight={400}
                                                                    color='black'
                                                                    bg='#F5F5F5'
                                                                    _hover={{ bg: 'gray.100', color: 'black', borderWidth: '2px', borderColor: '#1B7392' }}
                                                                    isSelected={clusterType === 'linker'}
                                                                    _selected={{ color: 'white', bg: '#990000' }}
                                                                >
                                                                    <Radio p='3' size='lg' bg='#F5F5F5' value='linker' w='100%'>
                                                                        <HStack>
                                                                            <Box ml='5'>
                                                                                <Text fontSize={24} fontWeight={700}>LINKER</Text>
                                                                                <Text fontSize={18} fontWeight={400} color='#1B7392'><Icon as={FiChevronRight} h={4} w={4} mr={1} alignSelf={'center'} />is an aggregator of links sources to ticketing websites.</Text>
                                                                                <Text fontSize={15} fontWeight={500}>ASSETS: markets, countries, cities, venues, customers, categories, events.</Text>
                                                                            </Box>
                                                                        </HStack>
                                                                    </Radio>
                                                                </Box>

                                                                {/* SHOP SITE */}
                                                                <Box
                                                                    p={2}
                                                                    mt='2'
                                                                    w='95%'
                                                                    textAlign={['left']}
                                                                    borderRadius='xl'
                                                                    borderWidth='2px'
                                                                    fontSize={20}
                                                                    fontWeight={400}
                                                                    color='black'
                                                                    bg='#F5F5F5'
                                                                    _hover={{ bg: 'gray.100', color: 'black', borderWidth: '2px', borderColor: '#1B7392' }}
                                                                    isSelected={clusterType === 'shop'}
                                                                    _selected={{ color: 'white', bg: '#990000' }}
                                                                >
                                                                    <Radio p='3' size='lg' bg='#F5F5F5' value='shop' w='100%'>
                                                                        <HStack>
                                                                            <Box ml='5'>
                                                                                <Text fontSize={24} fontWeight={700}>SHOP</Text>
                                                                                <Text fontSize={18} fontWeight={400} color='#1B7392'><Icon as={FiChevronRight} h={4} w={4} mr={1} alignSelf={'center'} />is an ecommerce store dedicated to ticket sales.</Text>
                                                                                <Text fontSize={15} fontWeight={500}>ASSETS: markets, countries, cities, venues, customers, categories, events, maps, tickets.</Text>
                                                                            </Box>
                                                                        </HStack>
                                                                    </Radio>
                                                                </Box>

                                                                {/* MARKETPLACE SITE */}
                                                                <Box
                                                                    p={2}
                                                                    mt='2'
                                                                    w='95%'
                                                                    textAlign={['left']}
                                                                    borderRadius='xl'
                                                                    borderWidth='2px'
                                                                    fontSize={20}
                                                                    fontWeight={400}
                                                                    color='black'
                                                                    bg='#F5F5F5'
                                                                    _hover={{ bg: 'gray.100', color: 'black', borderWidth: '2px', borderColor: '#1B7392' }}
                                                                    isSelected={clusterType === 'marketplace'}
                                                                    _selected={{ color: 'white', bg: '#990000' }}
                                                                >
                                                                    <Radio p='3' size='lg' bg='#F5F5F5' value='marketplace' w='100%'>
                                                                        <HStack>
                                                                            <Box ml='5'>
                                                                                <Text fontSize={24} fontWeight={700}>MARKETPLACE</Text>
                                                                                <Text fontSize={18} fontWeight={400} color='#1B7392'><Icon as={FiChevronRight} h={4} w={4} mr={1} alignSelf={'center'} />is a multi-seller ticket sales marketplace.</Text>
                                                                                <Text fontSize={15} fontWeight={500}>ASSETS: markets, countries, cities, venues, customers, categories, events, maps, tickets.</Text>
                                                                            </Box>
                                                                        </HStack>
                                                                    </Radio>
                                                                </Box>

                                                                {/* TOOL SITE */}
                                                                <Box
                                                                    p={2}
                                                                    mt='2'
                                                                    w='95%'
                                                                    textAlign={['left']}
                                                                    borderRadius='xl'
                                                                    borderWidth='2px'
                                                                    fontSize={20}
                                                                    fontWeight={400}
                                                                    color='black'
                                                                    bg='#F5F5F5'
                                                                    isSelected={clusterType === 'tool'}
                                                                    _selected={{ color: 'white', bg: '#990000' }}
                                                                    _hover={{ bg: 'gray.100', color: 'black', borderWidth: '2px', borderColor: '#1B7392' }}
                                                                >
                                                                    <Radio p='3' size='lg' bg='#F5F5F5' value='tool' w='100%'>
                                                                        <HStack>
                                                                            <Box ml='5'>
                                                                                <Text fontSize={24} fontWeight={700}>TOOL</Text>
                                                                                <Text fontSize={18} fontWeight={400} color='#1B7392'><Icon as={FiChevronRight} h={4} w={4} mr={1} alignSelf={'center'} />is a tool dedicated to ticketing.</Text>
                                                                                <Text fontSize={15} fontWeight={500}>ASSETS: markets, countries, cities, venues, customers, categories, events.</Text>
                                                                            </Box>
                                                                        </HStack>
                                                                    </Radio>
                                                                </Box>

                                                            </VStack>
                                                        </RadioGroup>

                                                    </GridItem>
                                                </FormControl>

                                                {/* Right column */}
                                                <FormControl>
                                                    <FormLabel htmlFor='clusterName' mb='0'>
                                                        <Text color='black' mb='0' fontSize={18} fontWeight={700}>
                                                            {T.clusters.ClusterName}
                                                        </Text>
                                                    </FormLabel>
                                                    <Input
                                                        isInvalid={!clusterName}
                                                        size='lg'
                                                        fontWeight={700}
                                                        fontSize='20'
                                                        id="clusterName"
                                                        name="clusterName"
                                                        value={clusterName}
                                                        onChange={onClusterNameChanged}
                                                        bg='yellow.100'
                                                    />

                                                    <FormLabel htmlFor='clusterLanguage' mt='5' mb='0'>
                                                        <Text color='black' mb='0' fontSize={18} fontWeight={700}>
                                                            {T.clusters.ClusterLanguage}
                                                        </Text>
                                                    </FormLabel>
                                                    {formFieldsLanguages.map((form, index) => {
                                                        return (
                                                            <HStack key={index}>

                                                                <Select
                                                                    isInvalid={!clusterLanguage}
                                                                    id="clusterLanguage"
                                                                    name="clusterLanguage"
                                                                    value={form.value}
                                                                    onChange={event => handleFormChangeLanguage(event, index)}
                                                                    bg='yellow.100'
                                                                >
                                                                    {options_clusterLanguage}
                                                                </Select>
                                                                {index !== 0 &&
                                                                    <IconButton
                                                                        size='xs'
                                                                        name="Remove"
                                                                        title={T.common.Delete}
                                                                        colorScheme='gray'
                                                                        aria-label={T.common.Delete}
                                                                        icon={<MinusIcon />}
                                                                        onClick={() => removeFieldsLanguage(index)}
                                                                    />
                                                                }
                                                            </HStack>
                                                        )
                                                    })}
                                                    <IconButton
                                                        size='xs'
                                                        title={T.common.Add}
                                                        onClick={addFieldsLanguage}
                                                        colorScheme='gray'
                                                        aria-label={T.common.Add}
                                                        icon={<AddIcon />}
                                                    />

                                                    <FormLabel htmlFor='clusterUrl' mt='5' mb='0'>
                                                        <Text color='black' mb='0' fontSize={18} fontWeight={700}>
                                                            {T.clusters.ClusterUrl}
                                                        </Text>
                                                    </FormLabel>
                                                    <Input
                                                        isInvalid={!clusterUrl}
                                                        id="clusterUrl"
                                                        name="clusterUrl"
                                                        value={clusterUrl}
                                                        onChange={onClusterUrlChanged}
                                                        bg='yellow.100'
                                                    />

                                                    <FormLabel htmlFor='clusterDatabaseURI' mt='5' mb='0'>
                                                        <Text color='black' mb='0' fontSize={18} fontWeight={700}>
                                                            {T.clusters.ClusterDatabaseURI}
                                                        </Text>
                                                    </FormLabel>
                                                    <Input
                                                        isInvalid={!clusterDatabaseURI}
                                                        id="clusterDatabaseURI"
                                                        name="clusterDatabaseURI"
                                                        value={clusterDatabaseURI}
                                                        onChange={onClusterDatabaseURIChanged}
                                                        bg='yellow.100'
                                                    />

                                                </FormControl>

                                            </HStack>

                                        </TabPanel>

                                        {/* Tab DATABASE FILTER */}
                                        <TabPanel minH={400}>

                                            <SimpleGrid minChildWidth='20%' spacing={1}>

                                                <Box m='2' p='2' bg='gray.50' borderRadius='10' borderWidth='1px' borderColor='gray.300'>
                                                    <FormLabel htmlFor='clusterMarkets' fontSize='18' fontWeight='700'>
                                                        {T.clusters.Markets}
                                                    </FormLabel>
                                                    <Checkbox isChecked={isChecked_optMarket} onChange={handleCheckboxChange_optMarket}>ALL</Checkbox>
                                                    <Select2
                                                        isMulti
                                                        hasStickyGroupHeaders
                                                        options={marketOptions}
                                                        placeholder="Select some markets..."
                                                        closeMenuOnSelect={false}
                                                        selectedOptionStyle="check"
                                                        hideSelectedOptions={false}
                                                        size="lg"
                                                        isDisabled={isChecked_optMarket}
                                                        onChange={onSelectMarketOptChanged}
                                                        value={optMarket}
                                                        formatOptionLabel={({ value, label }) => (
                                                            <Text fontSize={"16"} fontWeight={600}>{label}</Text>
                                                        )}
                                                        bg='red'
                                                    />
                                                </Box>

                                                <Box m='2' p='2' bg='gray.50' borderRadius='10' borderWidth='1px' borderColor='gray.300'>
                                                    <FormLabel htmlFor='clusterCountries' fontSize='18' fontWeight='700'>
                                                        {T.clusters.Countries}
                                                    </FormLabel>
                                                    <Checkbox isChecked={isChecked_optCountry} onChange={handleCheckboxChange_optCountry}>ALL</Checkbox>
                                                    <Select2
                                                        isMulti
                                                        hasStickyGroupHeaders
                                                        options={countryOptions}
                                                        placeholder="Select some countries..."
                                                        closeMenuOnSelect={false}
                                                        selectedOptionStyle="check"
                                                        hideSelectedOptions={false}
                                                        size="lg"
                                                        isDisabled={isChecked_optCountry}
                                                        onChange={onSelectCountryOptChanged}
                                                        value={optCountry}
                                                        formatOptionLabel={({ value, label }) => (
                                                            <Text fontSize={"16"} fontWeight={600}>{label}</Text>
                                                        )}
                                                    />
                                                </Box>

                                                <Box m='2' p='2' bg='gray.50' borderRadius='10' borderWidth='1px' borderColor='gray.300'>
                                                    <FormLabel htmlFor='clusterCities' fontSize='18' fontWeight='700'>
                                                        {T.clusters.Cities}
                                                    </FormLabel>
                                                    <Checkbox isChecked={isChecked_optCity} onChange={handleCheckboxChange_optCity}>ALL</Checkbox>
                                                    <Select2
                                                        isMulti
                                                        hasStickyGroupHeaders
                                                        options={cityOptions}
                                                        placeholder="Select some cities..."
                                                        closeMenuOnSelect={false}
                                                        selectedOptionStyle="check"
                                                        hideSelectedOptions={false}
                                                        size="lg"
                                                        isDisabled={isChecked_optCity}
                                                        onChange={onSelectCityOptChanged}
                                                        value={optCity}
                                                        formatOptionLabel={({ value, label }) => (
                                                            <Text fontSize={"16"} fontWeight={600}>{label}</Text>
                                                        )}
                                                    />
                                                </Box>
                                            </SimpleGrid>

                                            <SimpleGrid minChildWidth='20%' spacing={1}>
                                                <Box m='2' p='2' bg='gray.50' borderRadius='10' borderWidth='1px' borderColor='gray.300'>
                                                    <FormLabel htmlFor='clusterVenues' fontSize='18' fontWeight='700'>
                                                        {T.clusters.Venues}
                                                    </FormLabel>
                                                    <Checkbox isChecked={isChecked_optVenue} onChange={handleCheckboxChange_optVenue}>ALL</Checkbox>
                                                    <Select2
                                                        isMulti
                                                        hasStickyGroupHeaders
                                                        options={venueOptions}
                                                        placeholder="Select some venues..."
                                                        closeMenuOnSelect={false}
                                                        selectedOptionStyle="check"
                                                        hideSelectedOptions={false}
                                                        size="lg"
                                                        isDisabled={isChecked_optVenue}
                                                        onChange={onSelectVenueOptChanged}
                                                        value={optVenue}
                                                        formatOptionLabel={({ value, label }) => (
                                                            <Text fontSize={"16"} fontWeight={600}>{label}</Text>
                                                        )}
                                                    />
                                                </Box>

                                                <Box m='2' p='2' bg='gray.50' borderRadius='10' borderWidth='1px' borderColor='gray.300'>
                                                    <FormLabel htmlFor='clusterCustomers' fontSize='18' fontWeight='700'>
                                                        {T.clusters.Customers}
                                                    </FormLabel>
                                                    <Checkbox isChecked={isChecked_optCustomer} onChange={handleCheckboxChange_optCustomer}>ALL</Checkbox>
                                                    <Select2
                                                        isMulti
                                                        hasStickyGroupHeaders
                                                        options={customerOptions}
                                                        placeholder="Select some customers..."
                                                        closeMenuOnSelect={false}
                                                        selectedOptionStyle="check"
                                                        hideSelectedOptions={false}
                                                        size="lg"
                                                        isDisabled={isChecked_optCustomer}
                                                        onChange={onSelectCustomerOptChanged}
                                                        value={optCustomer}
                                                        formatOptionLabel={({ value, label }) => (
                                                            <Text fontSize={"16"} fontWeight={600}>{label}</Text>
                                                        )}
                                                    />
                                                </Box>

                                                <Box m='2' p='2' bg='gray.50' borderRadius='10' borderWidth='1px' borderColor='gray.300'>
                                                    <FormLabel htmlFor='clusterCategories' fontSize='18' fontWeight='700'>
                                                        {T.clusters.Categories}
                                                    </FormLabel>
                                                    <Checkbox isChecked={isChecked_optCategory} onChange={handleCheckboxChange_optCategory}>ALL</Checkbox>
                                                    <Select2
                                                        isMulti
                                                        hasStickyGroupHeaders
                                                        options={categoryOptions}
                                                        placeholder="Select some categories..."
                                                        closeMenuOnSelect={false}
                                                        selectedOptionStyle="check"
                                                        hideSelectedOptions={false}
                                                        size="lg"
                                                        isDisabled={isChecked_optCategory}
                                                        onChange={onSelectCategoryOptChanged}
                                                        value={optCategory}
                                                        formatOptionLabel={({ value, label }) => (
                                                            <Text fontSize={"16"} fontWeight={600}>{label}</Text>
                                                        )}
                                                    />
                                                </Box>

                                            </SimpleGrid>

                                            <SimpleGrid minChildWidth='20%' spacing={1}>
                                                <Box m='2' p='2' bg='gray.50' borderRadius='10' borderWidth='1px' borderColor='gray.300'>
                                                    <FormLabel htmlFor='clusterEvents' fontSize='18' fontWeight='700'>
                                                        {T.clusters.Events}
                                                    </FormLabel>
                                                    <Checkbox isChecked={isChecked_optEvent} onChange={handleCheckboxChange_optEvent}>ALL</Checkbox>
                                                    <Select2
                                                        isMulti
                                                        hasStickyGroupHeaders
                                                        options={eventOptions}
                                                        placeholder="Select some events..."
                                                        closeMenuOnSelect={false}
                                                        selectedOptionStyle="check"
                                                        hideSelectedOptions={false}
                                                        size="lg"
                                                        isDisabled={isChecked_optEvent}
                                                        onChange={onSelectEventOptChanged}
                                                        value={optEvent}
                                                        formatOptionLabel={({ eventName, eventDateStart, eventHourStart, venueName, cityName, countryName, marketName }) => (
                                                            <>
                                                                <Text fontSize={"17"} fontWeight={700}>{eventName} </Text>
                                                                <Text fontSize={"14"} fontWeight={500}>{eventDateStart}, {eventHourStart} </Text>
                                                                <Text fontSize={"15"} fontWeight={700}>{venueName} </Text>
                                                                <Text fontSize={"14"} fontWeight={600}>{cityName}, {countryName}, {marketName}</Text>
                                                            </>
                                                        )}
                                                    />
                                                </Box>
                                            </SimpleGrid>
                                        </TabPanel>

                                        {/* Tab Content & Metadata */}
                                        <TabPanel minH={400}>

                                            <Tabs variant='soft-rounded' colorScheme='blue' isFitted>
                                                <TabList
                                                    overflowX="auto"
                                                    css={css({
                                                        scrollbarWidth: 'none',
                                                        '::-webkit-scrollbar': { display: 'none' },
                                                        '-webkit-overflow-scrolling': 'touch',
                                                        boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                                        border: '0 none',
                                                    })}
                                                >
                                                    <Tab _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>{T.clusters.Markets}</Tab>
                                                    <Tab _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>{T.clusters.Countries}</Tab>
                                                    <Tab _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>{T.clusters.Cities}</Tab>
                                                    <Tab _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>{T.clusters.Venues}</Tab>
                                                    <Tab _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>{T.clusters.Customers}</Tab>
                                                    <Tab _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>{T.clusters.Categories}</Tab>
                                                    <Tab _selected={{ color: 'white', bg: '#2B6CB0' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>{T.clusters.Events}</Tab>
                                                </TabList>

                                                <TabPanels>

                                                    {/* Tab Markets */}
                                                    <TabPanel minH={400}>

                                                        { /*
                                                        {JSON.stringify(formFieldsTags)} <br />
                                                        {JSON.stringify(formFieldsTags[0].en[0].metaTitle)} <br />
                                                        {JSON.stringify(formFieldsTags[0].en[0])} <br />
                                                        {JSON.stringify(formFieldsTags[0])} <br />
                                                        {JSON.stringify(formFieldsTags[1])} <br />
                                                        {JSON.stringify(formFieldsTags)} <br />
                                                        */ }

                                                        {formFieldsLanguages.map((formFieldsLanguage, indexLang) => {

                                                            //console.log('indexLang = ' + indexLang)

                                                            /*
                                                            const keysTags = [{}]
                                                            if (formFieldsTags[indexLang]) {
                                                                keysTags = Object.keys(formFieldsTags[indexLang][formFieldsLanguage.value][0]);
                                                            } else {
                                                                keysTags = [{ 'varTag': 'valueTag' }];
                                                            }
                                                            */

                                                            if (formFieldsTags[indexLang] && indexLang <= formFieldsTags.length) {

                                                                //console.log("formFieldsTags[indexLang] = " + JSON.stringify(formFieldsTags[indexLang]))

                                                                //console.log("formFieldsLanguage.value = " + formFieldsLanguage.value)
                                                                //console.log("formFieldsTags " + JSON.stringify(formFieldsTags))
                                                                //console.log("formFieldsTags[indexLang][formFieldsLanguage.value][0].value = " + formFieldsTags[formFieldsLanguage.value])
                                                                console.log("formFieldsTags = " + JSON.stringify(formFieldsTags))
                                                                console.log("formFieldsLanguage.value = " + formFieldsLanguage.value)
                                                                console.log("formFieldsTags[indexLang][formFieldsLanguage.value] = " + formFieldsTags[formFieldsLanguage.value])
                                                                const keysTags = Object.keys(formFieldsTags[indexLang][formFieldsLanguage.value]);

                                                                return (
                                                                    <Box key={`lang_${indexLang}`} mb='5' p='2' _hover={{ bg: 'white', borderColor: 'gray.500' }} bg='gray.50' borderRadius='10' borderWidth='1px' borderColor='gray.300'>
                                                                        indexLang = {indexLang}
                                                                        <HStack>

                                                                            <Box w='75px'>
                                                                                <FormLabel htmlFor='clusterMarkets' fontSize='25' fontWeight='700'>
                                                                                    {formFieldsLanguage.value} :
                                                                                </FormLabel>
                                                                            </Box>
                                                                            <Box key={indexLang} w='100vh'>
                                                                                { /*
                                                                                -{JSON.stringify(keysTags)} <br />
                                                                                -{JSON.stringify(formFieldsLanguage.value)} <br />
                                                                                --{JSON.stringify(formFieldsTags[indexLang])} <br />
                                                                                ----{JSON.stringify(formFieldsTags[indexLang][formFieldsLanguage.value])} <br />
                                                                                */}

                                                                                {keysTags.map((formFieldsTag, indexTag) => {
                                                                                    //const keys2 = Object.keys(formFieldsTag);
                                                                                    return (
                                                                                        <Box key={`tag_${indexTag}`}>
                                                                                            {/*
                                                                                            {indexTag} = {formFieldsTag} = {JSON.stringify(formFieldsTags[indexLang][formFieldsLanguage.value][formFieldsTag])} <br />
                                                                                            */}


                                                                                            <FormControl>
                                                                                                <HStack>
                                                                                                    <Input
                                                                                                        name='tagName'
                                                                                                        value={formFieldsTag}
                                                                                                        onChange={event => handleFormChangeTag(event, indexTag)}
                                                                                                        size='lg'
                                                                                                        fontWeight={600}
                                                                                                        fontSize='18'
                                                                                                        bg='yellow.100'
                                                                                                        width='250px'
                                                                                                    />
                                                                                                    <ArrowForwardIcon boxSize={5} />
                                                                                                    <Input
                                                                                                        name='tagValue'
                                                                                                        value={formFieldsTags[indexLang][formFieldsLanguage.value][formFieldsTag]}
                                                                                                        onChange={event => handleFormChangeTag(event, indexTag)}
                                                                                                        size='lg'
                                                                                                        fontWeight={500}
                                                                                                        fontSize='18'
                                                                                                        bg='yellow.100'
                                                                                                    />
                                                                                                    <IconButton
                                                                                                        size='xs'
                                                                                                        name="Remove"
                                                                                                        title={T.common.Delete}
                                                                                                        colorScheme='gray'
                                                                                                        aria-label={T.common.Delete}
                                                                                                        icon={<MinusIcon />}
                                                                                                        onClick={() => removeFieldsTag(indexLang, indexTag)}
                                                                                                    />
                                                                                                </HStack>
                                                                                            </FormControl>
                                                                                        </Box>
                                                                                    )
                                                                                })}


                                                                            </Box>


                                                                            { /*
                                                                            <VStack>
                                                                                {formFieldsTags[0][formFieldsLanguage.value].map((formFieldsTag, indexTag) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Box w='100vh'>
                                                                                                {JSON.stringify(formFieldsTags[0])} <br />
                                                                                                {JSON.stringify(formFieldsTag)} <br />
                                                                                                {JSON.stringify(formFieldsTags[0][formFieldsLanguage.value])} <br />
                                                                                                {JSON.stringify(formFieldsLanguage)} <br />
                                                                                                {JSON.stringify(formFieldsLanguage.value)} <br />
                                                                                                {JSON.stringify(formFieldsTags[0]['en'][0])} <br />
                                                                                                {JSON.stringify(formFieldsTags[0][formFieldsLanguage.value][0])} <br />
                                                                                                {JSON.stringify(formFieldsTags[0][formFieldsLanguage.value])} <br />

                                                                                                {formFieldsTag && formFieldsTags[indexTag][formFieldsLanguage.value].map((formFieldsTag2, index) => {
                                                                                                    const keys = Object.keys(formFieldsTags[0][formFieldsLanguage.value][index]);
                                                                                                    return (
                                                                                                        <>

                                                                                                            <FormControl>
                                                                                                                <div key={index}>
                                                                                                                    {keys.map((key) => (
                                                                                                                        <div key={key}>
                                                                                                                            <HStack>
                                                                                                                                <Input
                                                                                                                                    name='tagName'
                                                                                                                                    value={key}
                                                                                                                                    onChange={event => handleFormChangeTag(event, index)}
                                                                                                                                    size='lg'
                                                                                                                                    fontWeight={600}
                                                                                                                                    fontSize='18'
                                                                                                                                    bg='yellow.100'
                                                                                                                                    width='250px'
                                                                                                                                />
                                                                                                                                <ArrowForwardIcon boxSize={5} />
                                                                                                                                <Input
                                                                                                                                    name='tagValue'
                                                                                                                                    value={formFieldsTag2[key]}
                                                                                                                                    onChange={event => handleFormChangeTag(event, index)}
                                                                                                                                    size='lg'
                                                                                                                                    fontWeight={500}
                                                                                                                                    fontSize='18'
                                                                                                                                    bg='yellow.100'
                                                                                                                                />
                                                                                                                                <IconButton
                                                                                                                                    size='xs'
                                                                                                                                    name="Remove"
                                                                                                                                    title={T.common.Delete}
                                                                                                                                    colorScheme='gray'
                                                                                                                                    aria-label={T.common.Delete}
                                                                                                                                    icon={<MinusIcon />}
                                                                                                                                    onClick={() => removeFieldsTag(index)}
                                                                                                                                />
                                                                                                                            </HStack>
                                                                                                                        </div>
                                                                                                                    ))}
                                                                                                                    <hr />
                                                                                                                </div>



                                                                                                            </FormControl>
                                                                                                        </>
                                                                                                    )
                                                                                                })}

                                                                                            </Box>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </VStack>
*/ }

                                                                        </HStack>
                                                                        <IconButton
                                                                            size='xs'
                                                                            title={T.common.Add}
                                                                            onClick={() => addFieldsTag(indexLang)}
                                                                            colorScheme='gray'
                                                                            aria-label={T.common.Add}
                                                                            icon={<AddIcon />}
                                                                        />

                                                                    </Box>
                                                                )

                                                            } else {

                                                                //const indexTag = 0
                                                                //const formFieldsTag = [{ 'varTag': 'varValue' }]
                                                                return (
                                                                    <Box key={`lang_${indexLang}`} mb='5' p='2' _hover={{ bg: 'white', borderColor: 'gray.500' }} bg='gray.50' borderRadius='10' borderWidth='1px' borderColor='gray.300'>
                                                                        indexLang = {indexLang}
                                                                        <HStack>

                                                                            <Box w='75px'>
                                                                                <FormLabel htmlFor='clusterMarkets' fontSize='25' fontWeight='700'>
                                                                                    {formFieldsLanguage.value} :
                                                                                </FormLabel>
                                                                            </Box>
                                                                            <Box key={indexLang}>



                                                                            </Box>

                                                                        </HStack>
                                                                        <IconButton
                                                                            size='xs'
                                                                            title={T.common.Add}
                                                                            onClick={() => addFieldsTag(indexLang)}
                                                                            colorScheme='gray'
                                                                            aria-label={T.common.Add}
                                                                            icon={<AddIcon />}
                                                                        />

                                                                    </Box>
                                                                )

                                                            }

                                                        })}
                                                    </TabPanel>

                                                </TabPanels>

                                            </Tabs>

                                        </TabPanel>

                                        {/* Tab Link */}
                                        <TabPanel minH={400}>
                                            <Grid
                                                templateRows='repeat(1, 1fr)'
                                                templateColumns='repeat(5, 1fr)'
                                                gap={5}
                                            >
                                                <GridItem colSpan={5}>
                                                    <Box>
                                                    </Box>
                                                </GridItem>
                                            </Grid>
                                        </TabPanel>

                                        {/* Tab SETUP */}
                                        <TabPanel minH={400}>
                                            <Grid
                                                templateRows='repeat(1, 1fr)'
                                                templateColumns='repeat(5, 1fr)'
                                                gap={5}
                                            >
                                                <GridItem colSpan={5}>
                                                    <Box>
                                                        <FormLabel htmlFor='Status' mt='5' mb='0'>
                                                            {T.clusters.Status}
                                                        </FormLabel>
                                                        <Switch
                                                            id='eventsStatus'
                                                            name="status"
                                                            colorScheme='green'
                                                            isChecked={clusterStatus}
                                                            onChange={onClusterStatusChanged}
                                                        />
                                                        <br />
                                                        <br />
                                                        <HStack>
                                                            <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }} fontWeight={600}>{T.common.Created}:</Text>
                                                            <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }}>{created}</Text>
                                                        </HStack>
                                                        <HStack>
                                                            <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }} fontWeight={600}>{T.common.Updated}:</Text>
                                                            <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }}>{updated}</Text>
                                                        </HStack>
                                                        <br />
                                                        <br />
                                                        {deleteButton}
                                                    </Box>
                                                </GridItem>
                                            </Grid>
                                        </TabPanel>

                                    </TabPanels>
                                </Tabs>

                            </form>

                        </Box>
                    </GridItem>

                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">
                            <div>
                                {optMarket.map(option => (
                                    <div key={option.value}>
                                        <p>{option.label}</p>
                                    </div>
                                ))}
                            </div>

                            <br />
                            clusterType = {clusterType}

                            <br />
                            formFieldsTags = {JSON.stringify(formFieldsTags)}

                            <br />
                            clusterLanguage = {JSON.stringify(clusterLanguage)}
                            <br />
                            formFieldsLanguages = {JSON.stringify(formFieldsLanguages)}

                            <br />
                            optMarket = {JSON.stringify(optMarket)}

                            <br />
                            optCountry = {JSON.stringify(optCountry)}

                            <br />
                            optCity = {JSON.stringify(optCity)}

                            <br />
                            optCustomer = {JSON.stringify(optCustomer)}

                            <br />
                            optCategory = {JSON.stringify(optCategory)}

                            <br />
                            optEvent = {JSON.stringify(optEvent)}

                        </Box>
                    </GridItem>

                </Grid >
            </Center >

        </>
    )

    return content
}

export default EditClusterForm