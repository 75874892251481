import { useGetUsersQuery } from "./usersApiSlice"
import User from './User'
import { Link } from 'react-router-dom'
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from "@fortawesome/free-solid-svg-icons"

const UsersList = () => {
    useTitle('DBY: Users List')

    const { isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const {
        data: users,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUsersQuery('usersList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {

        const { ids } = users

        const tableContent = ids?.length && ids.map(userId => <User key={userId} userId={userId} />)

        let menuContent
        if (isManager || isAdmin) {
            menuContent = (
                <>
                    <Link to="/dash/users/new">
                        <button
                            className="icon-button"
                            title={T.common.Add}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </Link>
                </>
            )
        }

        content = (
            <>
                <div className="form__title-row">
                    <h2>{T.users.UsersList}</h2>
                    <div className="form__action-buttons">
                        {menuContent}
                    </div>
                </div>
                <table className="table table--users">
                    <thead className="table__thead">
                        <tr>
                            <th scope="col" className="table__th user__username">{T.users.Username}</th>
                            <th scope="col" className="table__th user__roles">{T.users.Roles}</th>
                            <th scope="col" className="table__th user__edit">{T.users.Edit}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableContent}
                    </tbody>
                </table>
            </>
        )
    }

    return content
}
export default UsersList