import { apiSlice } from "../../app/api/apiSlice"

export const clusterApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getClusterEventsInfo: builder.query({
            query: ({ id }) => ({
                url: `/cluster/events/info`,
                method: 'POST',
                body: { id },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
        }),

        getCluster: builder.query({
            query: ({ id }) => ({
                url: `/cluster/${id}`,
                method: 'GET',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
        }),


        /*
                getEvents: builder.query({
                    query: (args) => {
                        console.log('args = ' + args)
                        // Destructuring Object
                        const { Pagination, Page } = args;
                        console.log('Pagination = ' + Pagination)
                        console.log('Page = ' + Page)
                        return {
                            // Returns url with multiple args
                            url: `/events/${Pagination}/${Page}`
                        }
                    },
                    transformResponse: responseData => {
                        const loadedEvents = responseData.map(event => {
                            event.id = event._id
                            return event
                        });
                        return eventsAdapter.setAll(initialState, loadedEvents)
                    },
                    providesTags: (result, error, arg) => {
                        if (result?.ids) {
                            return [
                                { type: 'Event', id: 'LIST' },
                                ...result.ids.map(id => ({ type: 'Event', id }))
                            ]
                        } else return [{ type: 'Event', id: 'LIST' }]
                    }
                }),
        */

        getClusterEvents: builder.query({
            query: ({ id, Pagination, Page }) => ({
                url: `/cluster/events`,
                method: 'POST',
                body: { id, Pagination, Page },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
        }),
        addEventsCluster: builder.mutation({
            query: ({ clusterId, clusterAddIdsList }) => ({
                url: `/cluster/addEvents`,
                method: 'POST',
                body: { clusterId, clusterAddIdsList },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            invalidatesTags: [
                { type: 'Cluster', id: "LIST" }
            ]
        }),
    }),
})

export const {
    useGetClusterEventsInfoQuery,
    useGetClusterQuery,
    useGetClusterEventsQuery,
    useAddEventsClusterMutation,
} = clusterApiSlice
