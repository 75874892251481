import NewClusterForm from './NewClusterForm'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const NewCluster = () => {
    useTitle('DBY: New Cluster')

    const content = <NewClusterForm />

    return content
}
export default NewCluster