import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom'

// import `ChakraProvider` component
import { ChakraProvider } from '@chakra-ui/react'


// Load screen
import { LoadingScreen } from "./screens";


// NAV
import Layout from './components/Layout'
import Public from './components/Public'
import Login from './features/auth/Login';
import DashLayout from './components/DashLayout'
import Welcome from './features/auth/Welcome'

// NOTES
import NotesList from './features/notes/NotesList'
import EditNote from './features/notes/EditNote'
import NewNote from './features/notes/NewNote'

// USERS
import UsersList from './features/users/UsersList'
import EditUser from './features/users/EditUser'
import NewUserForm from './features/users/NewUserForm'

// CLUSTERS
import ClustersList from './features/clusters/ClustersList'
import EditCluster from './features/clusters/EditCluster'
import NewCluster from './features/clusters/NewCluster'

// CLUSTER
import OverviewCluster from './features/clusters/OverviewCluster'


import Prefetch from './features/auth/Prefetch'

import PersistLogin from './features/auth/PersistLogin'
import RequireAuth from './features/auth/RequireAuth'
import { ROLES } from './config/roles'

import RequireLang from './features/lang/RequireLang'
import { LANGS } from './config/langs'

import useTitle from './hooks/useTitle';

//const lang = "en"

function App() {
    useTitle('DBY')

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);

    return (
        <>
            <ChakraProvider>
                {!loading ? (
                    <Routes>
                        <Route path="/" element={<Layout />}>

                            {/* public routes */}
                            <Route index element={<Public />} />
                            <Route path="login" element={<Login />} />

                            {/* Protected Routes */}
                            <Route element={<PersistLogin />}>
                                <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>
                                    <Route element={<RequireLang allowedLangs={[...Object.values(LANGS)]} />}>
                                        <Route element={<Prefetch />}>
                                            <Route path="dash" element={<DashLayout />}>

                                                <Route index element={<Welcome />} />

                                                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin]} />}>
                                                    <Route path="users">
                                                        <Route index element={<UsersList />} />
                                                        <Route path=":id" element={<EditUser />} />
                                                        <Route path="new" element={<NewUserForm />} />
                                                    </Route>
                                                </Route>

                                                <Route path="notes">
                                                    <Route index element={<NotesList />} />
                                                    <Route path=":id" element={<EditNote />} />
                                                    <Route path="new" element={<NewNote />} />
                                                </Route>

                                                <Route path="clusters">
                                                    <Route index element={<ClustersList />} />
                                                    <Route path=":id" element={<EditCluster />} />
                                                    <Route path="new" element={<NewCluster />} />
                                                </Route>

                                                <Route path="cluster">
                                                    <Route index element={<ClustersList />} />
                                                    <Route path=":id" element={<OverviewCluster />} />
                                                </Route>

                                            </Route>{/* End Dash */}
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>{/* End Protected Routes */}

                        </Route>
                    </Routes>
                ) : (
                    <LoadingScreen />
                )}
            </ChakraProvider>
        </>
    );
}

export default App;