import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import { apiSlice } from './api/apiSlice'
import { apiSliceOracly } from './api/apiSliceOracly'
import { setupListeners } from "@reduxjs/toolkit/query"
import authReducer from '../features/auth/authSlice'

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        [apiSliceOracly.reducerPath]: apiSliceOracly.reducer,
        auth: authReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            apiSlice.middleware,
            apiSliceOracly.middleware,
        ),
    devTools: false
})

setupListeners(store.dispatch)