import { store } from '../../app/store'

// Dby
import { usersApiSlice } from '../users/usersApiSlice';
import { notesApiSlice } from '../notes/notesApiSlice'

// Oracly
import { oraclyApiSlice } from '../oracly/oraclyApiSlice'

import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const Prefetch = () => {

    useEffect(() => {
        store.dispatch(usersApiSlice.util.prefetch('getUsers', 'usersList', { force: true }))
        store.dispatch(notesApiSlice.util.prefetch('getNotes', 'notesList', { force: true }))

        store.dispatch(oraclyApiSlice.util.prefetch('getMarkets', 'marketsList', { force: true }))
        store.dispatch(oraclyApiSlice.util.prefetch('getCountries', 'countriesList', { force: true }))
        store.dispatch(oraclyApiSlice.util.prefetch('getCities', 'citiesList', { force: true }))
        store.dispatch(oraclyApiSlice.util.prefetch('getVenues', 'venuesList', { force: true }))
        store.dispatch(oraclyApiSlice.util.prefetch('getCustomers', 'customersList', { force: true }))
        store.dispatch(oraclyApiSlice.util.prefetch('getCategories', 'categoriesList', { force: true }))
        store.dispatch(oraclyApiSlice.util.prefetch('getEvents', 'eventsList', { force: true }))
    }, [])

    return <Outlet />
}
export default Prefetch