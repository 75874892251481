import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import OverviewClusterBoard from './OverviewClusterBoard'
//import { useGetClustersQuery } from './clustersApiSlice'


import { useGetClusterQuery, useGetClusterEventsQuery } from './clusterApiSlice'
import useAuth from '../../hooks/useAuth'
import useTitle from '../../hooks/useTitle'
import PulseLoader from 'react-spinners/PulseLoader'

// Load screen
import { LoadingScreen } from "./../../screens";

// import `ChakraProvider` component
import { Box, Stack, Center, Grid, GridItem, HStack, Text, Spacer } from '@chakra-ui/react'

const EditCluster = () => {
    useTitle('DBY: Overview Cluster')

    const { id } = useParams()

    const { isManager, isAdmin } = useAuth()
    /*
        const { cluster } = useGetClustersQuery("clustersList", {
            selectFromResult: ({ data }) => ({
                cluster: data?.entities[id]
            }),
        })
    */
    const {
        data: dataCluster,
        isLoading: isLoadingCluster,
        isSuccess: isSuccessCluster,
        isError: isErrorCluster,
        error: errorCluster,
    } = useGetClusterQuery({ id });



    //console.log("dataCluster = " + JSON.stringify(dataCluster))
    //console.log("cluster.clusterDatabaseURI = " + JSON.stringify(dataCluster.clusterDatabaseURI))


    /*
    const { events } = useGetClusterEventsQuery("clustersList", {
        selectFromResult: ({ data }) => ({
            cluster: data?.entities[id]
        }),
    })
*/

    const {
        data: dataEvents,
        isLoading: isLoadingClusterEvents,
        isError: isErrorClusterEvents,
        error: errorClusterEvents,
    } = useGetClusterEventsQuery({ id });
    console.log("dataEvents = " + JSON.stringify(dataEvents))


    useEffect(() => {
        if (id) {
            // Trigger the API call when the component mounts or when clusterId changes
            // The API call will be made by the useGetClusterEventsQuery hook
        }
    }, [id]);




    /*
        const { events } = useGetClusterQuery("clusterEventsList", {
            selectFromResult: ({ data }) => ({
                databaseURI: data?.entities[id]
            }),
        })
        const { note } = useGetNotesQuery("notesList", {
            selectFromResult: ({ data }) => ({
                note: data?.entities[noteId]
            }),
        })
    */



    if (!dataCluster) return <PulseLoader color={"#FFF"} />

    if (!isManager && !isAdmin) {
        //        if (cluster.username !== username) {
        //            return <p className="errmsg">No access</p>
        //        }
    }

    let content

    if (isSuccessCluster) {
        content = <OverviewClusterBoard cluster={dataCluster} events={dataEvents} />
    }

    let body = (
        <>
            {!isLoadingCluster ? (
                <>
                    {content}
                </>
            ) : (
                <LoadingScreen section='CLUSTERS' />
            )}
        </>
    )

    return body
}
export default EditCluster