export const TRANSLATIONS_EN = {
    "common": {
        "Open": "Open",
        "Close": "Close",
        "Add": "Add",
        "AddAll": "Add all",
        "Edit": "Edit",
        "Save": "Save",
        "Delete": "Delete",
        "Refresh": "Refresh",
        "Actived": "Activé",
        "Disabled": "Désactivé",
        "Created": "Created",
        "Updated": "Updated",
        "Back": "Back",
        "UnselectAll": "Unselect all",
        "Import": "Import",
        "Filters": "Filters"
    },
    "clusters": {
        "ClustersList": "Liste des clusters",
        "Cluster": "Cluster",
        "Status": "Statut",
        "NewCluster": "New cluster",
        "EditCluster": "Cluster edit",
        "OverviewCluster": "Cluster overview",
        "ClusterName": "Cluster name",
        "ClusterType": "Cluster type",
        "ClusterLanguage": "Cluster language",
        "ClusterUrl": "Cluster url",
        "ClusterDatabaseURI": "Cluster databaseURI",
        "Markets": "Markets",
        "Countries": "Countries",
        "Cities": "Cities",
        "Venues": "Venues",
        "Customers": "Customers",
        "Categories": "Categories",
        "Events": "Events",
        "TranslatedName": "Translate name",
        "Action": "Action",
        "Edit": "Edit this cluster",
        "DeleteThisCluster": "Delete this cluster"
    },
    "events": {
        "EventsManager": "Event Manager",
        "EventsFinder": "Events finder",
        "EventsList": "Events list",
        "Event": "Events",
        "NewEvent": "New event",
        "EditEvent": "Edit event",
        "EventName": "Event Name",
        "DateStart": "Date Start",
        "HourStart": "Hour Start",
        "HourOpen": "Hour Open",
        "Category": "Category",
        "TourName": "Tour Name",
        "HasMultiDates": "Has Multi Dates",
        "UrlPicture": "Url Picture",
        "Picture": "Picture",
        "Venue": "Venue",
        "Ticket": "Ticket",
        "Map": "Map",
        "Source": "Source",
        "Cal": "Cal",
        "TicketCategory": "Category",
        "TicketName": "Name",
        "TicketPriceFacial": "Price Facial",
        "TicketPriceFee1": "Price Fee1",
        "TicketPriceFee2": "Price Fee2",
        "TicketPriceMin": "Price Min",
        "TicketPriceMax": "Price Max",
        "TicketDevice": "Device",
        "TicketType": "Type",
        "TicketOption": "Option",
        "TicketFeature": "Feature",

        "SourceType": "Type",
        "SourceUrl": "Url",

        "CalType": "Type",
        "CalDateOpen": "Date Open",
        "CalDateClose": "Date Close",

        "VenueName": "Venue Name",
        "VenueType": "Venue Type",
        "CityName": "City Name",
        "CountryName": "Country Name",
        "MarketName": "Market Name",
        "CustomerName": "Customer Name",
        "Placement": "Placement",
        "Attention": "Attention",
        "AliasName": "Alias Name",
        "Status": "Status",
        "Action": "Action",
        "Edit": "Edit",
        "DeleteThisEvent": "Delete this event"
    },
    "notes": {
        "NotesList": "Notes list",
        "Status": "Status",
        "Title": "Title",
        "Note": "Note",
        "Owner": "Owner",
        "Edit": "Edit",
        "Completed": "Completed",
        "NewNote": "New note",
        "EditNote": "Edit note",
        "AssignedTo": "Assigned to",
    },
    "users": {
        "UsersList": "Users list",
        "Username": "Username",
        "Roles": "Roles",
        "Edit": "Edit",
        "NewUser": "New user",
        "EditUser": "Edit user",
        "Password": "Password",
        "AssignedRoles": "Assigned roles",
        "Active": "Active",
    },
    "dash": {
        "Logout": "Logout",
        "Welcome": "Welcome",
        "Users": "Users",
        "Notes": "Notes",
        "Dash": "Dash",
        "Board": "Board",
    },
}