import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const clustersAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = clustersAdapter.getInitialState()

export const clustersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getClusters: builder.query({
            query: () => ({
                url: `/clusters`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedClusters = responseData.map(cluster => {
                    cluster.id = cluster._id
                    return cluster
                });
                return clustersAdapter.setAll(initialState, loadedClusters)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Cluster', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Cluster', id }))
                    ]
                } else return [{ type: 'Cluster', id: 'LIST' }]
            }
        }),
        addNewCluster: builder.mutation({
            query: initialCluster => ({
                url: `/clusters`,
                method: 'POST',
                body: {
                    ...initialCluster,
                }
            }),
            invalidatesTags: [
                { type: 'Cluster', id: "LIST" }
            ]
        }),
        updateCluster: builder.mutation({
            query: initialCluster => ({
                url: `/clusters`,
                method: 'PATCH',
                body: {
                    ...initialCluster,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Cluster', id: arg.id }
            ]
        }),
        deleteCluster: builder.mutation({
            query: ({ id }) => ({
                url: `/clusters`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Cluster', id: arg.id }
            ]
        }),
        searchClusters: builder.query({
            query: ({ searchStringCluster }) => ({
                url: `/clusters/search`,
                method: 'POST',
                body: { searchStringCluster },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),

        }),
    }),
})

export const {
    useGetClustersQuery,
    useAddNewClusterMutation,
    useUpdateClusterMutation,
    useDeleteClusterMutation,
    useSearchClustersQuery,
} = clustersApiSlice

// returns the query result object
export const selectClustersResult = clustersApiSlice.endpoints.getClusters.select()

// creates memoized selector
const selectClustersData = createSelector(
    selectClustersResult,
    clustersResult => clustersResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllClusters,
    selectById: selectClusterById,
    selectIds: selectClusterIds
    // Pass in a selector that returns the clusters slice of state
} = clustersAdapter.getSelectors(state => selectClustersData(state) ?? initialState)