import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

import { useGetClustersQuery } from "./../clusters/clustersApiSlice"
//import Cluster from "./Cluster"

import useAuth from '../../hooks/useAuth'
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'
import useTitle from "../../hooks/useTitle"


// immport screen
import { LoadingScreen } from "./../../screens";

// import `ChakraProvider` component
import { css, Box, Grid, GridItem, Center, Stack, HStack, Spacer, Badge, Button, Text } from '@chakra-ui/react'
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { AddIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from "@fortawesome/free-solid-svg-icons"

const ClusterSidebar = ({ boardClusterId, setBoardClusterId, clusterId, clusterName }) => {
    const navigate = useNavigate()

    const handleBoard = () => navigate(`/dash/cluster/${clusterId}`)

    let clusterMenu = (
        <>
            <Box onClick={handleBoard} cursor={'pointer'} borderRadius='xl' p={2} mb='2' fontSize={20} fontWeight={400} textAlign={['left']} color='black' border='10px' borderColor='black' bg='#333333' _hover={{ bg: 'blue.600', color: 'white', borderColor: 'red.600' }} w="100%">
                <HStack>
                    <Text color='white' fontSize={{ base: "12", sm: "12", md: "12", lg: "12", xl: "18" }} fontWeight={600}>{clusterName}</Text>
                    <Spacer />
                    <Badge color='black' bg='white' p='1'>151</Badge>
                    <Badge color='white' bg='red.600' p='1'>0</Badge>
                </HStack>
            </Box>
        </>
    )
    return clusterMenu
}


const ClusterBoard = ({ boardClusterId }) => {

    let clusterBoard = (
        <>
            <Box maxH='95vh' h='5000' borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['center']} borderColor='black' bg='white' w="100%">

            </Box>
        </>
    )
    return clusterBoard
}

const Welcome = () => {
    useTitle('DBY: Welcome')

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    const { isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const {
        data: clusters,
        isLoading,
        isSuccess,
        isError,
        //error
    } = useGetClustersQuery('clustersList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [boardClusterId, setBoardClusterId] = useState(0)

    let content

    if (isError) {
        if (isManager || isAdmin) {
        } else {
        }
    }

    if (isSuccess) {
        const { entities } = clusters

        const dataClusters = Object.values(entities);
        const sidebar = dataClusters.map(dataCluster => <ClusterSidebar key={dataCluster.clusterId} clusterId={dataCluster.id} clusterName={dataCluster.clusterName} setBoardClusterId={setBoardClusterId} boardClusterId={boardClusterId} />)
        const board = <ClusterBoard boardClusterId={boardClusterId} />

        content = (
            <>
                <Center bg='#45413C' p='0' color='white' axis='both'>
                    <Grid colSpan={12} w='100vw' maxW='3000' gap={5} p='5' templateColumns='repeat(12, 1fr)'>

                        <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12", "2xl": "12" }}>
                            <Grid templateColumns='repeat(12, 1fr)' gap={2}>

                                <GridItem colSpan={{ base: "3", sm: "3", md: "2", lg: "2", xl: "2", "2xl": "1" }}>
                                    {sidebar}
                                </GridItem>

                                <GridItem colSpan={{ base: "9", sm: "9", md: "10", lg: "10", xl: "10", "2xl": "11" }}>
                                    {board}
                                </GridItem>

                            </Grid>
                        </GridItem>

                    </Grid>
                </Center>
            </>
        )
    }

    let body = (
        <>
            {!isLoading ? (
                <Center bg='#45413C' p='0' color='white' axis='both'>
                    <Grid w='100vw' maxW='3000' minH='100vh' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                        <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                            <HStack color='white'>
                                <Box p='2' color='262626' textAlign={['left']} >
                                    <Text fontSize={{ base: "20", sm: "20", md: "20", lg: "24", xl: "28" }} fontWeight={700}><FontAwesomeIcon icon={faUsers} /> {T.dash.Dash}</Text>
                                </Box>
                                <Spacer />
                                <Box p='2' color='262626' textAlign={['right']}>
                                    menuContent
                                </Box>
                            </HStack>
                            {content}
                        </GridItem>
                    </Grid>
                </Center>
            ) : (
                <LoadingScreen section='CLUSTERS' />
            )}
        </>
    )

    return body
}
export default Welcome