import { useEffect } from "react"
import { useParams } from 'react-router-dom'
import EditClusterForm from './EditClusterForm'
import { useGetUsersQuery } from './../users/usersApiSlice'
import { useGetClustersQuery } from './clustersApiSlice'
import { useGetClusterQuery, useGetClusterEventsQuery } from './clusterApiSlice'
import { useGetMarketsQuery, useGetCountriesQuery, useGetCitiesQuery, useGetVenuesQuery, useGetCustomersQuery, useGetTicketsQuery, useGetCategoriesQuery, useGetEventsQuery } from "./../oracly/oraclyApiSlice"
import useAuth from '../../hooks/useAuth'
import useTitle from '../../hooks/useTitle'

// Load screen
import { LoadingScreen } from "./../../screens";

const EditCluster = () => {
    useTitle('DBY: Edit Cluster')

    const { id } = useParams()

    const { username, isManager, isAdmin } = useAuth()

    const timestamp = Date.now();

    const { users } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            users: data?.ids.map(id => data?.entities[id])
        }),
    })

    const {
        data: dataCluster,
        isLoading: isLoadingCluster,
        isSuccess: isSuccessCluster,
        isError: isErrorCluster,
        error: errorCluster,
        refetch: refetchCluster
    } = useGetClusterQuery({
        id: id
    })

    const {
        data: dataMarkets,
        isLoading: isLoadingMarkets,
        isSuccess: isSuccessMarkets,
        isError: isErrorMarkets,
        error: errorCMarkets
    } = useGetMarketsQuery()

    const {
        data: dataCountries,
        isLoading: isLoadingCountries,
        isSuccess: isSuccessCountries,
        isError: isErrorCountries,
        error: errorCountries
    } = useGetCountriesQuery()

    const {
        data: dataCities,
        isLoading: isLoadingCities,
        isSuccess: isSuccessCities,
        isError: isErrorCities,
        error: errorCities
    } = useGetCitiesQuery()

    const {
        data: dataVenues,
        isLoading: isLoadingVenues,
        isSuccess: isSuccessVenues,
        isError: isErrorVenues,
        error: errorVenues
    } = useGetVenuesQuery()

    const {
        data: dataCustomers,
        isLoading: isLoadingCustomerss,
        isSuccess: isSuccessCustomers,
        isError: isErrorCustomers,
        error: errorCustomers
    } = useGetCustomersQuery()

    const {
        data: dataCategories,
        isLoading: isLoadingCategories,
        isSuccess: isSuccessCategories,
        isError: isErrorCategories,
        error: errorCategories
    } = useGetCategoriesQuery()

    const {
        data: dataEvents,
        isLoading: isLoadingEvents,
        isSuccess: isSuccessEvents,
        isError: isErrorEvents,
        error: errorEvents
    } = useGetEventsQuery()




    // Forcez la mise à jour lors du chargement initial
    useEffect(() => {
        refetchCluster();
    }, [refetchCluster]); // Assurez-vous de mettre refetchCluster dans la dépendance pour éviter les avertissements


    let content

    if (isSuccessCluster && isSuccessCountries) {
        let body2 = JSON.stringify(dataCountries)
        const myArray = Object.values(dataCountries);
        //console.log("Data countries:", countries);
        console.log("dataCluster:", dataCluster);
        console.log("dataCountries:", dataCountries);

        content = <EditClusterForm cluster={dataCluster} markets={dataMarkets} countries={dataCountries} cities={dataCities} venues={dataVenues} customers={dataCustomers} categories={dataCategories} events={dataEvents} />
    }

    if (isErrorCluster) {

    }

    let body = (
        <>
            {!isLoadingCluster ? (
                <>
                    {content}
                </>
            ) : (
                <LoadingScreen section='CLUSTERS' />
            )}

        </>
    )

    return body
}
export default EditCluster