import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetClustersQuery } from './clustersApiSlice'
import { memo } from 'react'
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { Icon, IconButton, Tr, Td, Tooltip, Image, Text, Box, AspectRatio, HStack } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons'

const Cluster = ({ clusterId }) => {

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const { cluster } = useGetClustersQuery("clustersList", {
        selectFromResult: ({ data }) => ({
            cluster: data?.entities[clusterId]
        }),
    })

    const navigate = useNavigate()

    if (cluster) {

        const handleOverview = () => navigate(`/dash/cluster/${clusterId}`)
        const handleEdit = () => navigate(`/dash/clusters/${clusterId}`)

        return (
            <Tr _hover={{ color: '#333333', border: '2px', borderColor: 'blue.600' }}>
                <Td>
                    {cluster.clusterStatus
                        ? <Icon as={CheckIcon} w={4} h={4} color='green.500' title={T.common.Actived} />
                        : <Icon as={CloseIcon} w={4} h={4} color='red.500' title={T.common.Disabled} />
                    }
                </Td>

                <Td>
                    <Text fontSize={20} fontWeight={700}>{cluster.clusterName}</Text>
                </Td>

                <Td>
                    {cluster.clusterType}
                </Td>

                <Td isNumeric>
                    <Tooltip hasArrow label={T.common.Edit} bg='gray.300' closeOnClick={true} color='black' placement='left'>
                        <IconButton
                            colorScheme='gray'
                            aria-label='New'
                            icon={<EditIcon />}
                            onClick={handleOverview}
                            _hover={{ bg: 'blue.600', color: 'white', borderColor: 'blue.600' }}
                        />
                    </Tooltip>
                    <Tooltip hasArrow label={T.common.Edit} bg='gray.300' closeOnClick={true} color='black' placement='left'>
                        <IconButton
                            colorScheme='gray'
                            aria-label='New'
                            icon={<EditIcon />}
                            onClick={handleEdit}
                            _hover={{ bg: 'blue.600', color: 'white', borderColor: 'blue.600' }}
                        />
                    </Tooltip>
                </Td>
            </Tr>
        )

    } else return null
}

const memoizedCluster = memo(Cluster)

export default memoizedCluster