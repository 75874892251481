import { Outlet } from 'react-router-dom'
import DashHeader from './DashHeader'
import DashFooter from './DashFooter'

// import `ChakraProvider` component
import { Grid } from '@chakra-ui/react'

const DashLayout = () => {
    return (
        <>
            <Grid
                templateRows='1fr'
                templateColumns='repeat(1, 1fr)'
                bg='#333333'
            >
                <DashHeader />
                <Outlet />
                <DashFooter />
            </Grid>
        </>
    )
}
export default DashLayout