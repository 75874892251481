import { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import useAuth from '../hooks/useAuth'
import useLang from '../hooks/useLang'
import useTranslate from '../hooks/useTranslate'

// import `ChakraProvider` component
import { Box, Flex, Avatar, HStack, Text, IconButton, Button, Menu, MenuGroup, MenuButton, MenuList, MenuItem, MenuDivider, useDisclosure, useColorModeValue, Stack } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { HamburgerIcon, CloseIcon, SettingsIcon } from '@chakra-ui/icons'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard, faFilePen, faUsers } from "@fortawesome/free-solid-svg-icons"


import { useSendLogoutMutation } from '../features/auth/authApiSlice'


const Links = ['Mapy', 'Sourcy', 'Crony']

const NavLink = (props) => {
    const { children } = props
    return (
        <Box
            as="a"
            px={2}
            py={1}
            rounded={'md'}
            _hover={{
                textDecoration: 'none',
                bg: useColorModeValue('gray.700', 'gray.200'),
            }}
            href={'#'}
        >
            {children}
        </Box>
    )
}

const DashHeader = () => {
    const { isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const navigate = useNavigate()

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [sendLogout, {
        //isLoading,
        isSuccess,
        //isError,
        //error
    }] = useSendLogoutMutation()

    useEffect(() => {
        if (isSuccess) navigate('/')
        window.scrollTo(0, 0);
    }, [isSuccess, navigate])



    const content = (
        <>
            <Box bg='#1a202c' px={4}>
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack spacing={4} alignItems={'center'}>
                        <Box>
                            <Link to="/dash">
                                <Text fontSize={{ base: "28", sm: "28", md: "25", lg: "25", xl: "25" }} fontWeight={600}>DBY.</Text>
                            </Link>
                        </Box>

                        <Flex display={{ base: "none", md: "block" }}>

                            <Menu>
                                <MenuButton m='1' as={Button} colorScheme='blue' size={'sm'}>
                                    CLUSTER
                                </MenuButton>
                                <MenuList bg='#DDDDDD'>
                                    <MenuGroup title='CLUSTER' fontWeight={700} bg='#2B6CB0' color='white' m='2' p='1'>
                                        <Link to="/dash/clusters">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faClipboard} /> Manage</MenuItem>
                                        </Link>
                                    </MenuGroup>
                                </MenuList>
                            </Menu>

                        </Flex>

                    </HStack>
                    <Flex alignItems={'center'}>

                        {(isManager || isAdmin) &&
                            <Menu>
                                <MenuButton as={Button} variant={'outline'} leftIcon={<SettingsIcon />} mr='2' colorScheme='blue' size={'sm'}>
                                    Admin
                                </MenuButton>
                                <MenuList bg='#DDDDDD'>
                                    <MenuGroup title='SETUP' fontWeight={700} bg='#2B6CB0' color='white' m='2' p='1'>
                                        <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '>Section</MenuItem>
                                    </MenuGroup>
                                    <MenuDivider />
                                    <MenuGroup title='MANAGE' fontWeight={700} bg='#2B6CB0' color='white' m='2' p='1'>
                                        <Link to="/dash/notes">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faFilePen} /> {T.dash.Notes}</MenuItem>
                                        </Link>
                                        <Link to="/dash/users">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faUsers} /> {T.dash.Users}</MenuItem>
                                        </Link>
                                    </MenuGroup>
                                </MenuList>
                            </Menu>
                        }

                        <Menu>
                            <MenuButton
                                as={Button}
                                rounded={'full'}
                                variant={'link'}
                                cursor={'pointer'}
                                minW={0}>
                                <Avatar
                                    size={'sm'}
                                    src={
                                        'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                                    }
                                />
                            </MenuButton>
                            <MenuList>
                                <MenuItem>Profile</MenuItem>
                                <MenuItem>Options</MenuItem>
                                <MenuDivider />
                                <MenuItem onClick={sendLogout}>Logout</MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>
                            {Links.map((link) => (
                                <NavLink key={link}>{link}</NavLink>
                            ))}
                        </Stack>
                    </Box>
                ) : null}
            </Box>
        </>
    )

    return content
}
export default DashHeader